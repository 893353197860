import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useGetCorporacionesQuery } from '../../services/negocios/queryApi'
import { TcorpItem, Tcorporaciones } from '../../services/negocio/negociosApiTypes'
import { Tnegocios } from '../../services/negocios/apiTypes'
import { useLocation, useRouteLoaderData,  } from 'react-router-dom'

import CorporacionRow from '../negocioRow/corporacionRow'
import { TrootState, useAppSelector } from '../../app/store'

export default function CorporacionesList() {
  const location = useLocation()
  const [ negocios, setNegocios ] = useState<Tcorporaciones|undefined>(undefined)

  const buscador = useAppSelector((state: TrootState) => state.buscador)
  const position = useAppSelector((state: TrootState) => state.position)

  const { data: negociosApi, error, isFetching, isLoading, refetch } =  useGetCorporacionesQuery({
    search: buscador.searchText !== '' ? buscador.searchText : undefined,
  } , {
    pollingInterval: 0
  })

  useEffect(() => {
    if (negociosApi) {
      setNegocios(negociosApi)
    }
  }, [negociosApi])

  return(
    <NegociosListContainer
      isMobile={location.pathname}
    >
      <div style={{ minHeight: '75px', minWidth: '100%'}} />
      {  negocios && negocios.items.map((negocio: TcorpItem) => (
        <CorporacionRow
          { ...negocio }
          key={negocio.corp_id}
        />
      ))}
    </NegociosListContainer>
  )
}

interface TnegociosListCont { isMobile: string }
const NegociosListContainer = styled.div<TnegociosListCont>`
  display: flex;
  flex-flow: column nowrap;
 
  z-index: -10;
  width: 100%;
  
  ${(args) => args.isMobile === '/' && `
    padding-bottom: 150px;
  `}
  color: white;
`