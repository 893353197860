import axios from 'axios'
import { app } from '../servicesConfig'
import { AxiosResponse, AxiosError } from 'axios'


const BACKEND_URL = app.BACKEND_URL

export function likeNegocio(negocioId: string) {

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/like/${negocioId}`
  };

  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}


export function dislikeNegocio(negocioId: string) {

  const options = {
    method: 'GET',
    withCredentials: true,
    url: `${BACKEND_URL}/dislike/${negocioId}`
  };

  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    axios(options)
    .then(function (response) {
      resolve(response);
    })
    .catch(function (error) {
      console.log(error)
      reject(error);
    })
  })
}