import { createBrowserRouter, redirect } from 'react-router-dom'
import store from './store'
import { negociosApi } from '../services/negocios/queryApi'

import App from './App'
import Layout from './Layout'
import Home from '../views/Home/Home'

import PageNotFound from '../views/PageNotFound/PageNotFound'
import Tarjeta from '../components/tarjeta/tarjeta'
import Promos from '../views/Promos/promos'
import MyAccount from '../views/Account/MyAccount'
import Favs from '../views/Favs/Favs'
import Corporacion from '../views/Corporacion/Corporacion'
import CorporacionData from '../views/Corporacion/CorporacionData'
import Policy from '../views/policy/policy'

const getPositions = () => { return new Promise((resolve, reject) => {
  console.log('a')
    try {
      console.log('b')
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log('c')
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude  
          })      
      }, function(error) {
        console.log('d')
        console.log('No navigator enabled')
        resolve(null)
      }) 
    } catch(error) {
      console.log(`NegociosList (getPositions) : ${error}`)
      reject(error)
    }
  })
}

export const router = createBrowserRouter([
  {
    element: <App/>,
    id: "root",
    loader: async () => {
      console.log(1)
      let positions: undefined | { lat: number, lng: number } = undefined
      console.log(2)

      if ("geolocation" in navigator) positions = (await getPositions() as any)
      console.log(3)

      const promise = store.dispatch(negociosApi.endpoints.getNegocios.initiate({
        lat: positions?.lat? positions.lat : undefined,
        lng: positions?.lng? positions.lng : undefined,
      }))
      console.log(4)

      try {
        console.log(5)
        const response = await promise.unwrap()
        console.log(6)
        const { data: negocios, isError, error, isLoading, isSuccess } = response;
        console.log(7)
        console.log('response router api data')
        console.log(response)
        return response
      } catch(e) {
        console.log(8)
        console.log('Error on router: ')
        console.log(e)
        // see https://reactrouter.com/en/main/fetch/redirect
        //return redirect("/login")
      }
      finally {
        console.log(9)
        promise.unsubscribe()
      }

    },
    children: [
      {
        element: <Layout/>,
        children: [
          {
            index: true,
            element: <Home/>,
            id:'negocios',
            handle: { scrollMode: 'pathname' },
          },
          {
            path: "/negocios/:negocio_id",
            element: <Tarjeta/>
          },
          {
            path: "/corporaciones",
            element: <Corporacion/>
          },
          {
            path: "/corporaciones/:corporacion_id",
            element: <CorporacionData/>
          },
          {
            path: "/promos",
            element: <Promos/>
          },
          {
            path: "/mi-cuenta",
            element: <MyAccount/>
          },
          {
            path: "/favs",
            element: <Favs/>
          },
          {
            path: "/policy",
            element: <Policy/>
          }
        ]
      }
    ]
  },
  
])

export default router