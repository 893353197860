import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'

interface thisProps {
  children: JSX.Element,
  closeModal: () => void,
  closeComponent?: JSX.Element
  customId?: string,
}
export default function ModalNoIcon(props: thisProps) {
  const { closeComponent, closeModal, customId } = props

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return(() => {
      document.body.style.overflow = 'initial';
    })
  }, [])

  const escFunction = useCallback((event: any) => {
    if (event.key === "Escape") {
      props.closeModal()
    }
  }, [props.closeModal])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & { id: string}
    const element = event.target as Element

    if (customId) {
      if (element.closest(`#closeModalIcon-customId`)) props.closeModal()
      if (target.id.match(`modalBackground-${customId}`)) props.closeModal()
      if (target.id.match(`closeModalIcon-${customId}`)) props.closeModal()
      event.stopPropagation()
    } else  {
      if (element.closest('#closeModalIcon')) props.closeModal()
      if (target.id.match(/modalBackground|closeModalIcon/)) props.closeModal()      
    }    
  }
  
  return(
    <ModalBackground
      id={ customId ? `modalBackground-${customId}` : `modalBackground`}
      style={{zIndex: 1001 }}
      onClick={hiddeModalFn}
    >

      { closeComponent &&
        ({ ...closeComponent,
          props: {
            ...closeComponent.props,
            id: customId ? `closeModalIcon-${customId}` : 'closeModalIcon',
          }
        })
      }

      { props.children }

    </ModalBackground>
  )
}

const ModalBackground = styled.div` 
  display: grid;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1999; 
`