import React, { useEffect, useState, useCallback } from 'react'
import { Location, useMatches, useSearchParams } from 'react-router-dom'
import { Route, Routes, useLocation, Outlet, ScrollRestoration } from 'react-router-dom'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from './store'
import { cleanNegocio } from '../services/tarjeta/tarjetaSlice' 
import { cleanSlice } from '../services/editNegocio/editNegocioSlice'
import { useGoogleLogin, useGoogleOneTapLogin  } from '@react-oauth/google'
import { setActiveView } from './AppUiSilce'
import { setPosition } from '../services/position/positionSlice'
import { getPositions } from '../libs/utils'
import './App.css'

import Cookies from 'universal-cookie'
import { fetchUserData } from '../services/auth/userSlice'
import useConfig from '../hooks/useConfig'
import 'bootstrap/dist/css/bootstrap.min.css'

import Home from '../views/Home/Home'
import Tarjeta from '../components/tarjeta/tarjeta'

import PopUp from '../components/popUp/popUp'
import Modal from '../components/modal/modal'
import SponsorsModal from '../components/sponsors/sponsorsModal'
import Loader from '../components/loader/loader'

const cookies = new Cookies()

function App() {
  const config = useConfig()
  const dispatch = useAppDispatch()
  const  location = useLocation()
  const [ showModal, setShowModal] = useState<boolean>(false)
  const [ searchParams, ] = useSearchParams()

  const appUi = useAppSelector((state: TrootState) => state.appUi)
  const user = useAppSelector((state: TrootState) => state.user)
  const position = useAppSelector((state: TrootState) => state.position)
  const background = location.state && location.state.background

  const displayModal = () => setShowModal(true)
  const hiddeModal = () => setShowModal(false)

  async function gpsUpdater() {
    console.log('Updating position...')
    let positions: undefined | { lat: number, lng: number } = undefined
    if ("geolocation" in navigator) positions = (await getPositions() as any)

    if (positions?.lat && positions?.lng) {
      dispatch(setPosition({
        lat: positions.lat,
        lng: positions.lng
      }))
    }
  }

  useEffect(() => {
    if (!searchParams.get('negocio')) {
      dispatch(cleanNegocio())
      dispatch(cleanSlice())
    }
  }, [searchParams])

  useEffect(() => {
    if (location.pathname === '/' && !searchParams.get('negocio'))  displayModal()
    if (cookies.get(config.app.COOKIE_USER)) dispatch(fetchUserData())

    gpsUpdater()
  }, [])

  useEffect(() => {
    const loadedView = appUi.views.find((view) => view.path === location.pathname)

    if (loadedView) {
      dispatch(setActiveView(loadedView))
    } else {
      const notFound = appUi.views.find((view) => view.path === '/*')
      if (notFound) dispatch(setActiveView(notFound))
    }
  }, [location.pathname, appUi.views])
  
  useEffect(() => {
    if (user.status === 'logout') {
      window.location.replace(config.app.BACKEND_URL)
    }
  }, [user])

  const getKey = useCallback(
    (location: Location, matches: ReturnType<typeof useMatches>) => {
      // console.log('getying key...')

      let match = matches.find((m) => (m.handle as any)?.scrollMode);
      if ((match?.handle as any)?.scrollMode === 'pathname') {
        return location.pathname;
      }

      return location.key;
    },
    []
  )

  return (
    <AppContainer>

      { background &&
        <Routes>
          <Route path="/" element={<Home/>} >
            <Route path="/negocios/:negocio_id" element={ <Tarjeta />  }/>
          </Route>
        </Routes>
      } 

      <Outlet/>

      <ScrollRestoration getKey={getKey} />

      <PopUp/>

      { showModal &&
        <Modal
          closeModal={hiddeModal}
        >
          <SponsorsModal
            hiddeModal={hiddeModal}
          />
        </Modal>
      }

      {
        user.status === 'loading' &&
        <Loader/>
      }

    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  min-height: 600px;
`