import React from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import { setFacebook, setInstagram, setLinkedin, setTiktok, setYoutube, setWeb, setTelegram, setPinterest, setTwitter, setEmail } from '../crearNegocioSlice'

export default function RedesData() {
  const dispatch = useAppDispatch()
  const publicar = useAppSelector((state: TrootState) => state.crearNegocio)

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'negocio-facebook')
      dispatch(setFacebook(event.target.value))

    if (event.target.name === 'negocio-instagram')
      dispatch(setInstagram(event.target.value))

    if (event.target.name === 'negocio-tiktok')
      dispatch(setTiktok(event.target.value))

    if (event.target.name === 'negocio-youtube')
      dispatch(setYoutube(event.target.value))

    if (event.target.name === 'negocio-linkedin')
      dispatch(setLinkedin(event.target.value))

    if (event.target.name === 'negocio-web')
      dispatch(setWeb(event.target.value))

    if (event.target.name === 'negocio-telegram')
      dispatch(setTelegram(event.target.value))

    if (event.target.name === 'negocio-pinterest')
      dispatch(setPinterest(event.target.value))

    if (event.target.name === 'negocio-twitter')
      dispatch(setTwitter(event.target.value))

    if (event.target.name === 'negocio-email')
      dispatch(setEmail(event.target.value))
  }

  return(
    <RedesDataContainer>

      <Title> Redes Sociales </Title>

      <InputContainer>
        <Label>Facebook <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-facebook'
          placeholder="Facebook"
          value={publicar.data.facebook ? publicar.data.facebook : ''}
          defaultValue={publicar.data.facebook ? publicar.data.facebook : undefined}
          setted={publicar.data.facebook ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Instagram <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-instagram'
          placeholder="Instagram"
          value={publicar.data.instagram ? publicar.data.instagram : ''}
          defaultValue={publicar.data.instagram ? publicar.data.instagram : undefined}
          setted={publicar.data.instagram ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>TikTok <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-tiktok'
          placeholder="TikTok"
          value={publicar.data.tiktok ? publicar.data.tiktok : ''}
          defaultValue={publicar.data.tiktok ? publicar.data.tiktok : undefined}
          setted={publicar.data.tiktok ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Youtube <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-youtube'
          placeholder="Youtube"
          value={publicar.data.youtube ? publicar.data.youtube : ''}
          defaultValue={publicar.data.youtube ? publicar.data.youtube : undefined}
          setted={publicar.data.youtube ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>LinkedIn <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-linkedin'
          placeholder="LinkedIn"
          value={publicar.data.linkedin ? publicar.data.linkedin : ''}
          defaultValue={publicar.data.linkedin ? publicar.data.linkedin : undefined}
          setted={publicar.data.linkedin ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Pagina Web <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-web'
          placeholder="Pagina Web"
          value={publicar.data.web ? publicar.data.web : ''}
          defaultValue={publicar.data.web ? publicar.data.web : undefined}
          setted={publicar.data.web ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Telegram <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-telegram'
          placeholder="Telegram (+591 xxxxxxxx)"
          value={publicar.data.telegram ? publicar.data.telegram : ''}
          defaultValue={publicar.data.telegram ? publicar.data.telegram : undefined}
          setted={publicar.data.telegram ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Pinterest <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-pinterest'
          placeholder="Pinterest"
          value={publicar.data.pinterest ? publicar.data.pinterest : ''}
          defaultValue={publicar.data.pinterest ? publicar.data.pinterest : undefined}
          setted={publicar.data.pinterest ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Twitter <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-twitter'
          placeholder="Twitter"
          value={publicar.data.twitter ? publicar.data.twitter : ''}
          defaultValue={publicar.data.twitter ? publicar.data.twitter : undefined}
          setted={publicar.data.twitter ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Email Negocio <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-email'
          placeholder="Email"
          value={publicar.data.email ? publicar.data.email : ''}
          defaultValue={publicar.data.email ? publicar.data.email : undefined}
          setted={publicar.data.email ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

    </RedesDataContainer>
  )
}

interface Tselect { setted: boolean }
const RedesDataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  overflow: auto;
`

const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 13px;
  `
  const Opcional = styled.span`
    font-size: 10px;
    color: gray;
  `
  const InputNegocio = styled.input<Tselect>`
    width: 300px;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 3px 3px 3px 10px;
    border: solid 1px gray;
    border-radius: 5px;
    border-color: #00000088;
    &:focus {
      outline: none;
    }
    ${(args) => !args.setted && `
      border-color: #00000066;
    `}
    ${(args) => args.setted && `
      color: black;
      border-color: black;
    `}
  `

const Title = styled.div`
  font-family: Nunito;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
`