import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { TrootState, useAppSelector } from '../../app/store'

import Logo from '../../assets/images/ui/vcards.png'
import logoWhite from '../../assets/images/ui/vcards_white.png'


export default function Header() {
  const { pathname } = useLocation()

  const { activeView } = useAppSelector((state: TrootState) => state.appUi)
  const admin = useAppSelector((state: TrootState) => state.admin)
  const corp = useAppSelector((state: TrootState) => state.corporacion)

  useEffect(() => {
    console.log()
  }, [])

  return(
    <Background>

      <Container isAdmin={admin.active} >
        <BackgroundImage
          isAdmin={admin.active}
          src={admin.active ? logoWhite : Logo}
        />
      </Container>

      <Name>
        {
          admin.active ? 'VCards Business (admin)' : 
          pathname.match('/corporaciones') ?  'VCards Corporaciones' :
          'VCards Business'
        }
      </Name>

      <Title>

        {
          pathname === '/' ? 'Negocios Cercanos' :
          pathname === '/promos' ?  'Promociones' :
          
          pathname === '/mi-cuenta' ? 'Mi Cuenta' :
          pathname.match('/corporaciones/') ?  corp.corp_name ?? 'Corporaciones2' :
          pathname.match('/corporaciones') ?  'Corporaciones' :
          
          'Favoritos'
        }

      </Title>

    </Background>
  )
}

const Background = styled.div`
  display: flex;
  align-items: flex-start;
  height: 75px;
  background-color: #88888899;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 20%);

  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1;
`
interface isAdmin { isAdmin: boolean }
const Container = styled.div<isAdmin>`
  height: 65px;
  background-color: black;
  background: linear-gradient(90deg, #000000 30%, #434343 100%);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${(args) => args.isAdmin && `
    background: linear-gradient(90deg, #940700 30%, #434343 100%);
  `}
`

const BackgroundImage = styled.img<isAdmin>`
  position: absolute;
  height: 200px;
  left: -60px;
  top: -70px;
  opacity: 0.3;
  ${(args) => args.isAdmin && `
    top: -30px;
    left: -55px;
    height: 150px;
  `}
`

const Name = styled.div`
  position: absolute;
  color: #ffcc00;
  left: 110px;
  top: 8px;
  text-transform: uppercase;
  opacity: 0.75;
  font-family: Roboto;
  font-weight: 600;
  font-size: 12px;
`

const Title = styled.div`
  position: absolute;
  color: white;
  left: 110px;
  bottom: 16px;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 21px;
`