import React from "react"
import styled from "styled-components"
import { TrootState, useAppDispatch, useAppSelector } from "../../../app/store" 
import { setReferer } from "../crearNegocioSlice"

export default function RefererCodeStep() {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state: TrootState) => state.user)

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {

    if (event.target.name === 'negocio-codigo-referido')
      dispatch(setReferer(event.target.value.toUpperCase()))
  }
  

  return(
    <RefererContainer>
      <Title> Codigo de Referido </Title>

      <InputContainer>
        <Label> Codigo de Referido </Label>
        <InputNegocio
          type='text'
          name='negocio-codigo-referido'
          placeholder={
            user.data?.role === 'registrador' ? user.data?.refererCode : ''
          }
          // defaultValue={publicar.data.nombre_contacto ? publicar.data.nombre_contacto : undefined}
          onChange={inputChange}
        />
      </InputContainer>

    </RefererContainer>
  )
}


const RefererContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;

`

const Title = styled.div`
  font-family: Nunito;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
`

const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 13px;
  `
  const InputNegocio = styled.input`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    width: 300px;
    margin: 5px 0px 0px 0px;
    padding: 5px 5px 5px 15px;
    border: solid 1px gray;
    border-radius: 5px;
    border-color: #00000088;
    text-transform: uppercase;
    &:focus {
      outline: none;
    }
    
  `