import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TinitialState } from "./buscadorTypes";

const initialState: TinitialState = {
  state: 'hidden',
  searchText: '',
}

const buscarSlice = createSlice({
  name: 'buscar',
  initialState,
  reducers: {
    setShowBuscador(state) {
      state.state = 'initial'
    },
    setHiddeBuscador(state) {
      state.state = 'hidden'
      state.searchText = ''
    },
    setSearchText(state, action: PayloadAction<string>) {
      state.state = 'searching'
      state.searchText = action.payload
    }
  }
})

export const {
  setShowBuscador,
  setHiddeBuscador,
  setSearchText
} = buscarSlice.actions

export default buscarSlice.reducer