import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TinitialState, TadminViews } from './adminTypes'

const initialState: TinitialState = {
  active: false,
  crearNegocio: false,
  view: 'list'
}

const adminNavSlice = createSlice({
  name: 'adminNav',
  initialState,
  reducers: {
    setAdminView(state, action: PayloadAction<TadminViews>) {
      state.view = action.payload
    },
    setEnableAdmin(state) {
      state.active = true
    },
    setDisableAdmin(state) {
      state.active = false
    },
    setShowCrearNegocio(state) {
      state.crearNegocio = true
    },
    setHiddeCrearNegocio(state) {
      state.crearNegocio = false
    }
  }
})

export const {
  setAdminView,
  setEnableAdmin,
  setDisableAdmin,
  setShowCrearNegocio,
  setHiddeCrearNegocio
} = adminNavSlice.actions

export default adminNavSlice.reducer