import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Tview, TuiState  } from "./types"

const views: Tview[] = [
  { title: 'Negocios', path: '/', theme: 'ligth', show: 'ever' },
  { title: 'Novedades', path: '/promos', theme: 'ligth', show: 'ever' },
  { title: 'Corporaciones', path: '/corporaciones', theme: 'ligth', show: 'ever' },
  { title: 'Corporaciones', path: '/corporaciones/:corporacion_id', theme: 'ligth', show: 'ever' },
  { title: 'Mi Cuenta', path: '/mi-cuenta', theme: 'ligth', show: 'ever' },
  { title: 'Favoritos', path: '/favs', theme: 'ligth', show: 'ever' },
//   { title: 'Gallery', path: '/gallery', theme: 'ligth', show: 'ever' },
//   { title: 'Marketplace', path: '/marketplace', theme: 'ligth', show: 'ever' },
//   { title: 'Sign In', path: '/login', theme: 'ligth', show: 'noLogged' },
//   { title: 'My Account', path: '/profile', theme: 'ligth', show: 'logged' },
//   { title: 'User Settings', path: '/profile-settings', theme: 'ligth', show: 'never' },
//   { title: 'Not Found', path: '/*', theme: 'ligth', show: 'never' },
]

const initialState: TuiState = {
  loading: true,
  showSidebar: false,
  showModal: false,
  activeView: views[0],
  views
}

const appUiSlice = createSlice({
  name: 'appUi',
  initialState,
  reducers: {
    setActiveView(state, action: PayloadAction<Tview>) {
      state.activeView = action.payload
    },
    hiddeSidebar(state) {
      state.showSidebar = false
    },
    displaySidebar(state) {
      state.showSidebar = true
    },
    hiddeLoader(state) {
      state.loading = false
    },
    showLoader(state) {
      state.loading = true
    }
  }
})

export const {
  setActiveView,
  displaySidebar,
  hiddeSidebar,
  hiddeLoader,
  showLoader,
} = appUiSlice.actions

export default appUiSlice.reducer