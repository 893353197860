import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TinitialState } from "./editNegocioTypes";


const initialState: TinitialState = {
  editing: undefined,
  updating: 'initial',
  steps: ['info' , 'social', 'map'],
  step: 0,
  dataSteps: [
    [
      { prop: 'nombre', value: undefined, edited: false },
      { prop: 'tipo', value: undefined, edited: false },
      { prop: 'localidad', value: undefined, edited: false },
      { prop: 'phone_negocio', value: undefined, edited: false },
    ],
    [
      { prop: 'facebook', value: undefined, edited: false },
      { prop: 'instagram', value: undefined, edited: false },
      { prop: 'tiktok', value: undefined, edited: false },
      { prop: 'youtube', value: undefined, edited: false },
      { prop: 'linkedin', value: undefined, edited: false },
      { prop: 'web', value: undefined, edited: false },
      { prop: 'telegram', value: undefined, edited: false },
      { prop: 'pinterest', value: undefined, edited: false },
      { prop: 'twitter', value: undefined, edited: false },
      { prop: 'email', value: undefined, edited: false },
    ]
  ],
  location: {
    lat: undefined,
    lng: undefined,
    googlePlaceId: undefined,
    text: undefined
  }
}

const editNegocioSlice = createSlice({
  name: 'editNegocioSlice',
  initialState,
  reducers: {
    setEditingNegocio: (state, action: PayloadAction<string|undefined>) => {
      state.editing = action.payload
    },
    setUpdating: (state, action: PayloadAction<'initial'|'updating'|'updated'>) => {
      state.updating = action.payload
    },
    cleanSlice: (state) => {
      state.editing = initialState.editing
      state.updating = initialState.updating
      state.steps = initialState.steps
      state.step = initialState.step
      state.dataSteps = initialState.dataSteps
    },
    setSimpleData: (state, action: PayloadAction<{ group: number, prop: string, value: string | undefined }>) => {
      const { group, prop, value } = action.payload
      let dataGroup: undefined | string = undefined
      
      
        state.dataSteps[group] = state.dataSteps[group].map((item) => {
          if (item.prop === prop) {
            item.value = value

            if (value !== undefined) item.edited = true
            else item.edited = false
          }

          return item
        })
      


    },
    setAlreadyEdited: (state, action: PayloadAction<number>) => {
      state.dataSteps[action.payload] = state.dataSteps[action.payload].map((item) => {
        return {
          ...item,
          edited: false
        }
      })
    },
    nextStep: (state) => {
      if (state.step < (state.steps.length - 1)) 
      state.step = state.step + 1
    },
    backStep: (state) => {
      if (state.step !== 0)
      state.step = state.step - 1
    },
    setEditLocation: (state, action: PayloadAction<{ text: undefined|string, googlePlaceId: undefined|string, lat: number, lng: number }>) => {
      state.location = action.payload
    },
    cleanEditLocation: (state) => {
      state.location = initialState.location
    }
  }
})

export const {
  setEditingNegocio,
  setAlreadyEdited,
  setSimpleData,
  cleanSlice,
  setUpdating,
  nextStep,
  backStep,
  setEditLocation,
  cleanEditLocation,
} = editNegocioSlice.actions

export default editNegocioSlice.reducer