import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../app/store'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'

import list from '../../assets/icons/menu/list.png'
import listWhite from '../../assets/icons/menu/list_white.png'
import ads from '../../assets/icons/menu/ads.png'
import adsWhite from '../../assets/icons/menu/ads_white.png'
import account from '../../assets/icons/menu/account.png'
import accountWhite from '../../assets/icons/menu/account_white.png'
import fav from '../../assets/icons/menu/fav.png'
import favWhite from '../../assets/icons/menu/fav_white.png'
import corporaciones from '../../assets/icons/menu/corporacion.png'
import corporacionesWhite from '../../assets/icons/menu/corporacion_white.png'

export default function MainMenu() {
  const location = useLocation()
  const params = useParams()
  const appUi = useAppSelector((state: TrootState) => state.appUi)
  
  useEffect(() => {
    console.log()
  }, [])

  return(
    <Background>
      <Container>
        <Link to='/promos' >
          <Option
            onClick={() => {
              
            }}
            selected={appUi.activeView.path == '/promos' ? true : false}
          >
            <OptionIcon
              src={appUi.activeView.path == '/promos' ? adsWhite : ads }
            />
          </Option>
        </Link>

        <Link to='/' >
          <Option
            selected={location.pathname == '/' ? true : false}
          >
            <OptionIcon
              src={ location.pathname == '/' ? listWhite : list }
            />
          </Option>
        </Link>

        <Link to='/corporaciones' >
          <Option
            selected={ location.pathname.match('/corporaciones') ? true : false}
          >
            <OptionIcon
              src={ location.pathname.match('/corporaciones') ? corporacionesWhite : corporaciones }
            />
          </Option>
        </Link>

        <Link to='/mi-cuenta' >
          <Option
            selected={appUi.activeView.path == '/mi-cuenta' ? true : false}
          >
            <OptionIcon 
              src={ appUi.activeView.path == '/mi-cuenta' ? accountWhite : account }
            />
          </Option>
        </Link>

        <Link to='/favs' preventScrollReset>
          <Option
            selected={appUi.activeView.path == '/favs' ? true : false}
          >
            <OptionIcon
              src={ appUi.activeView.path == '/favs' ? favWhite : fav }
            />
          </Option>
        </Link>

      </Container>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  align-items: flex-end;
  height: 75px;
  background-color: #88888899;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 20%);

  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 1;
`
const Container = styled.div`
  height: 65px;
  background-color: white;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

interface Toption { selected: boolean }
const Option = styled.div<Toption>`
  border-radius: 5px;
  padding: 2px 5px;
  ${(args) => args.selected && `
    background: #888888;
  `}
`
const OptionIcon = styled.img`
  height: 35px;
`