import React from 'react'
import styled from 'styled-components'

import CorporacionesDataList from '../../components/list_items/corporacionDataList'


export default function CorporacionData() {
  
  return(
    <Container
      className='view'
    >

      {/* <CorporacionesDataList/> */}
      <CorporacionesDataList/>
      {/* <Buscador/>
      <BuscarDot/> */}

    </Container>
  )
}

const Container = styled.div`

`