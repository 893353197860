import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { useGetNegocioQuery } from '../../services/negocio/queryApi'
import { cleanNegocio, setNegociData, setNegocioId } from '../../services/tarjeta/tarjetaSlice'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { setUpdating } from '../../services/editNegocio/editNegocioSlice'
import { TnegocioItem } from '../../services/negocio/negociosApiTypes'
import useConfig from '../../hooks/useConfig'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './tarjeta.css'

import logoBack from '../../assets/icons/tarjeta/vcards.png'
import shareQrImg from '../../assets/icons/tarjeta/share_qr.png'

import TarjetaMap from './tarjetaMap'
import SocialLinks from './components/socialButtons/socialButtons'
import TarjetaNavigator from './components/navbar/navigator'
import ModalNoIcon from '../modal/modalNoCloseIcon'
import AdminOptions from './components/adminOptions/adminOptions'
import OptionsButton from './components/options/options_buttons'
import EditingTarjeta from './components/editing/editing_main'


interface imageEffect {
  alt: string,
  src: string,
  className?: string,
  height?: number,
  width?: number,
}

export type TtarjetaStates = 'tarjeta' | 'mapa'
interface TtoShare {  files: File[] }

export default function Tarjeta() {
  
  const dispatch = useAppDispatch()
  const params = useParams()
  const [searchParams,setSearchParams] = useSearchParams()  
  const { app } = useConfig()
  const [tarjetaState, setTarjetaState] = useState<TtarjetaStates>('tarjeta')
  const [qrToShare, setQRToShare] = useState<TtoShare|undefined>(undefined)
  const [ qrBlob, setQrBlob ] = useState<Blob|undefined>(undefined)
  const [ mainImgBlob, setMainImgBlob ] = useState<Blob|undefined>(undefined)
  const [ squareImg, setSquareImg ] = useState<Blob|undefined>(undefined)
  const { pathname } = useLocation()
  
  const isAdmin = useAppSelector((state: TrootState) => state.user.data?.role === 'admin' || state.user.data?.role === 'registrador' )
  const editingNegocio = useAppSelector((state: TrootState) => state.editNegocio)
  const admin = useAppSelector((state: TrootState) => state.admin)

  const closeTarjetaModal = () =>  {
    document.getElementById('redbol-back-to-negocios')?.click()
  }

  const { data: negocio, error, isFetching, isSuccess, isLoading, refetch } = useGetNegocioQuery({
    negocioId: params.negocio_id ? params.negocio_id : searchParams.get('negocio')
  } , {
    pollingInterval: 0
  })

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    if (negocio) {
      fetch(`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/vcard_qr.png`,{
        headers: myHeaders,
      })
      .then((qrImg) => qrImg.blob())
      .then( async (qrBlobResp) => {
          setQrBlob(qrBlobResp)
      })
      .catch((err) => {
        console.log('QR img fetch error: ' + err )
      })

      fetch(`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/thumb_h500.jpg`, {
        headers: myHeaders,
      })
      .then((res) => res.blob())
      .then( async (blob) => {
        setMainImgBlob(blob)
      })
      .catch((err) => {
        console.log('QR share mainImg fetch error: ' + err )
      })

      fetch(`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/thumb_500x500.jpg`, {
        headers: myHeaders,
      })
      .then((res) => res.blob())
      .then( async (blob) => {
        setSquareImg(blob)
      })
      .catch((err) => {
        console.log('SquareImg fetch error: ' + err )
      })
    }
  }, [negocio])

  useEffect(() => {
    if (negocio && qrBlob && squareImg) {
      prepareQRShare(negocio)
    }
  }, [negocio, qrBlob, squareImg])

  useEffect(() => {
    if (editingNegocio.updating === 'updated') {
      dispatch(setUpdating('initial'))
      refetch()
    }
  }, [editingNegocio])

  async function prepareQRShare(negocio: TnegocioItem) {

    fetch(`https://s3.sa-east-1.amazonaws.com/mibarrio.static.files/template/marco/marcoQR.png`)
    .then((marcoRes) => marcoRes.blob())
    .then( async (marcoBlob) => {
      let canvas = document.createElement('canvas');
      canvas.width = 500;
      canvas.height = 500;
      let context = canvas.getContext('2d');

      const negocioImg = document.createElement("img");

      negocioImg.onload = () => {
        const marcoImg = document.createElement("img");

        marcoImg.onload = () => {
          const qrImg = document.createElement("img");

          qrImg.onload = () => {
            if (context) {

              context.drawImage(negocioImg, 0, 0, 500, 500 );
              context.drawImage(marcoImg, 0, 0, 500, 500 );                
              context.drawImage(qrImg, 0, 0, 180, 180, 300, 300, 200, 200 );

              if (negocio.nombre) {                
                context.font = 'bold 20px sans-serif';
                context.fillStyle = "#000000";
                context.fillText(negocio.nombre, 10, 25);
              }

              canvas.toBlob(async (imageWithMarcoBlob) => {
                if (imageWithMarcoBlob) {

                  const filesArray = [
                    new File(
                      [imageWithMarcoBlob],
                      `vcards-${negocio.negocio_id}.jpg`,
                      {
                        type: "image/jpeg",
                        lastModified: new Date().getTime()
                      }
                    )
                  ];
                  
                  setQRToShare({ files: filesArray })
                }
              })  
            }
          }
          qrImg.src = URL.createObjectURL(qrBlob as Blob);
        }
        marcoImg.src = URL.createObjectURL(marcoBlob);
      }
      negocioImg.src = URL.createObjectURL(squareImg as Blob);  
      
    })
    .catch((err) => {
      console.log('QR share marcoImg fetch error: ' + err )
    })

  }

  const tarjetaNavigate = (to: TtarjetaStates) => setTarjetaState(to)

  useEffect(() => {
    if (isSuccess) {
      dispatch(setNegociData(negocio))
      dispatch(setNegocioId(negocio.negocio_id))
    }
  }, [negocio])

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      effect="blur"
      src={image.src}
      height={ 220 }
      width={ '100%' }
      />
  ), [isSuccess]);

  return(
    <ModalNoIcon 
      closeModal={() => {
        if (searchParams.get('negocio')) {
          searchParams.delete('negocio');
          setSearchParams(searchParams);
        } else {
          closeTarjetaModal()
        }
        dispatch(cleanNegocio())
      }}
    >
      <>
      <TarjetaContainer
        className='tarjeta'
      >
        { tarjetaState === 'mapa' &&
          <TarjetaMap/>
        }

        { tarjetaState === 'tarjeta' &&
          <>
            <ImageContainer>

              { !isSuccess && <LogoBackImg src={logoBack}/> }

              { isSuccess && mainImgBlob &&
                <MyImage
                  className='tarjeta-main-image'
                  height={220}
                  alt="negocio main image"
                  // src={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/thumb_h500.jpg`}
                  src={URL.createObjectURL(mainImgBlob)}
                />
              }

              <OptionsButton/>

            </ImageContainer>

            <Body>
              { isSuccess ?
                  <>
                    <Nombre> { negocio.nombre } </Nombre>
                    <Tipo> { negocio.tipo } </Tipo>

                    <SocialLinks negocio={negocio} />
                  </>
                :
                  <LoadingContainer>
                    <LoadingTitle className='gradient'/>
                    <LoadingTitle className='gradient'/>

                    <LoadingSocial>
                      <LoadingSocialItem className='gradient'/>
                      <LoadingSocialItem className='gradient'/>
                      <LoadingSocialItem className='gradient'/>
                    </LoadingSocial>
                  </LoadingContainer>
              }
            </Body>

            <QRContainer>
              { isSuccess && qrBlob &&
                <>
                <QRImage
                  // src={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/vcard_qr.png`}
                  src={ URL.createObjectURL(qrBlob)}
                />

                <ShareAnimation
                  className='fade-in-qr'
                  onClick={() => {
                    if (qrToShare) navigator.share(qrToShare)
                  }}
                >
                  <ShareImg src={shareQrImg}/>
                </ShareAnimation>
                </>
              }
            </QRContainer>

          </>
        }

        <TarjetaNavigator
          navigate={tarjetaNavigate}
          tarjetaState={tarjetaState}
        />

        <Link
          id="redbol-back-to-negocios"
          to={'..'}
          // preventScrollReset
        />

      </TarjetaContainer>

      {
        ((isAdmin && admin.active) || pathname === '/mi-cuenta') &&
        <AdminOptions/>
      }

      { editingNegocio.editing &&
        <EditingTarjeta/>
      }

      </>

    </ModalNoIcon>
  )
}

const TarjetaContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0px;
  width: 90vw;
  height: 85vh;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: auto;
`

const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  background: linear-gradient(160deg, #d6ae7b -10%, #eacda3 5%, #ffffff 30%);
  background: linear-gradient(200deg, #fdf498  -10%, #fdf498   -15%, #ffffff 25%);
  height: calc(85vh - 220px);
  overflow-y: scroll;
`
  const Nombre = styled.div`
    font-family: Roboto;
    width: 260px;
    width: 100%;
    text-align: center;
    
    font-weight: 600;
    font-size: 20px;
    margin: 10px 0px 10px 10px;
    max-width: calc(100% - 140px);
  `

  const Tipo = styled.div`
    font-family: Nunito;
    width: 270px;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    margin-left: 20px;
    max-width: calc(100% - 154px);
  `
const ImageContainer = styled.div`
  position: relative;
  min-height: 220px;
  max-height: 220px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
  background: linear-gradient(180deg, #ff9a9e  0%, #fad0c4 100%);
  background: linear-gradient(130deg, #d6ae7b  50%, #fdf498 100%);
`
  const NegocioImage = styled.img`
    height: 220px;
    width: 100%;
    object-fit: cover;
  `



const QRContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;
  border-radius: 5px;
  background: linear-gradient(135deg, #29323c00   0%, #485563 100%);
  position: absolute;
  opacity: 0.9;
  top: 180px;
  right: 10px;
  overflow: hidden;
`
  const ShareAnimation = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 114px;
    height: 114px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  `
  const ShareImg = styled.img`
    width: 80px;
  `

const Loading = styled.div`
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background: linear-gradient(to right, #f4f4f4 0%, #ffffff 50%, #f4f4f4 100%);

  //background: linear-gradient(to right, #030014 0%, #0C0052 50%, #060029 100%);
  background-size: 700px 50px;
  position: relative;
`
  const LoadingContainer = styled.div`
    width: 100%;
    padding-top: 10px;
  `
   
  const LoadingTitle = styled(Loading)`
    width: 100%;
    height: 25px;
    border-radius: 5px;
    margin: 5px 0px 5px 20px;
    max-width: calc(100% - 160px);
  `
    const LoadingSocial = styled.div`
      margin-top: 50px;
    `
    const LoadingSocialItem = styled(Loading)`
      width: 80vw;
      height: 40px;
      border-radius: 5px;
      margin: 10px 0px 10px 20px;
      
    `

const LogoBackImg = styled.img`
  height: 180px;
  /* margin-left: 50px; */
  /* margin-top: 10px; */
  filter: blur(1px);
  -webkit-filter: blur(1px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`

const QRImage = styled.img`
  border-radius: 5px;
  max-width: 110px;
  max-height: 110px;
`

