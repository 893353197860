import React from 'react'
import styled from 'styled-components'
import { Carousel } from "react-bootstrap";
import { useAppDispatch } from '../../app/store';
import { useSearchParams } from 'react-router-dom';
import { setNegocioId } from '../../services/tarjeta/tarjetaSlice';

import jdaza from '../../assets/images/sponsors/jose_daza.jpg'
import loscusis from '../../assets/images/sponsors/los_cusis.jpg'
import metaverse from '../../assets/images/sponsors/metaverese.jpg'



const sponsorsData = [
  {
    title: 'Jose Daza',
    desc: '',
    image: jdaza,
    id: 'jose_daza_tv_mfZRp',
  },
  {
    title: 'Los Cusis',
    desc: '',
    image: loscusis,
    id: undefined,
  },
  {
    title: 'Metaverse',
    desc: '',
    image: metaverse,
    id: 'metaverse_Pa1A9',
  },
]

function shuffle(array: any[]) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

interface thisProps { hiddeModal: () => void }
export default function SponsorsModal(props: thisProps) {
  const dispatch = useAppDispatch()
  const [, setSearchParam] = useSearchParams()
  
  return(
    <SponsorsContainer>

      <Title>
        {'Nuestros\nAuspiciadores'}
      </Title>

      <Carousel
        variant="dark"
        controls={false}
        interval={3500}
      >
        { shuffle(sponsorsData).map((sponsor, idx) => (
          <Carousel.Item key={`requisito-${idx}`}>
            <SponsorCard>
              <SponsorTitle> { sponsor.title } </SponsorTitle>
              <Icon
                src={sponsor.image}
                onClick={() => {
                  if (sponsor.id) {
                    setSearchParam({ negocio: sponsor.id })
                    dispatch(setNegocioId(sponsor.id))
                    props.hiddeModal()
                  }
                }}
              />
              
            </SponsorCard>
          </Carousel.Item>
        ))}
      </Carousel>
    </SponsorsContainer>
  )
}

const SponsorsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 300px;
  background-color: white;
  border-radius:15px;
`
const Title = styled.div`
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  white-space: pre-line;
  text-align: center;
  text-transform: uppercase;
`

const SponsorCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 200px;
`

const Icon = styled.img`
  width: 230px;
`
const SponsorTitle = styled.p`
  font-family: Nunito;
  font-weight: 700;
`
