import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { useGetFavoritosQuery } from '../../services/negocios/queryApi'
import { TnegocioItem } from '../../services/negocio/negociosApiTypes'
import { Tnegocios } from '../../services/negocios/apiTypes'
import NegocioRow from '../../components/negocioRow/negocioRow'

export default function Favs() {
  const [ negocios, setNegocios ] = useState<Tnegocios>({items: [],
    count: 0,
    totalResults: 0,
  })

  const { data: negociosApi, error, isSuccess, isFetching, isLoading, refetch } = useGetFavoritosQuery({} , {
    pollingInterval: 0
  })

  useEffect(() => {
    if (negociosApi) {
      setNegocios(negociosApi)
    }
  }, [negociosApi])

  return(
    <NegociosListContainer
    // isMobile={location.pathname}
  >
    <div style={{ minHeight: '75px', minWidth: '100%'}} />
    {  negocios && (negocios as Tnegocios).items.map((negocio: TnegocioItem) => (
      <NegocioRow
        { ...negocio }
        key={negocio.negocio_id}
      />
    ))}
  </NegociosListContainer>
  )
}

const Container = styled.div``

interface TnegociosListCont { isMobile: string }
const NegociosListContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
 
  z-index: -10;
  width: 100%;
  
  
  color: white;
`