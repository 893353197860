import axios from 'axios'
import { app } from '../servicesConfig'
import { AxiosResponse, AxiosError } from 'axios'
import { TupdateNegocioInfo, TupdateResponse } from './editNegocioApiTypes'


const BACKEND_URL = app.BACKEND_URL

export function updateNegocioInfo(payload: TupdateNegocioInfo) {
  const axiosOptions = {
    method: 'PUT',  
    withCredentials: true,
    url: `${BACKEND_URL}/user/negocio/${payload.negocio_id}`,
    data: JSON.stringify({
      type: "upload_data",
      ...payload
    }),
  };

  return new Promise<AxiosResponse<TupdateResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}