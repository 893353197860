import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import useViewport from '../../hooks/useViewport'
import { useGoogleLogin, useGoogleOneTapLogin  } from '@react-oauth/google'
import { googleLogin } from '../../services/auth/userSlice'
import Login from './sign-in/login'
import ResetPassword from './sign-in/resetPassword'
import ResetPasswordSuccess from './sign-in/resetPassSuccess'
import { useAppDispatch } from '../../app/store'

import Register from './sign-up/register'
import Verificacion from './sign-up/verification'
import SignUpSuccess from './sign-up/signUpSuccess'

// import logo from '../../assets/icons/ui/logo_ligth.png'

export type TsignInStep = 'login' | 'resetPassword' | 'resetPasswordSuccess' | 'signup' | 'verificacion' | 'signUpSucces'

const responsive = {
  mobileThreshold: 950,
  SDthreshold: 1340,
}


export default function AuthComponent() {
  const dispatch = useAppDispatch()
  const [step, setStep] = useState<TsignInStep>('login')
  const { bodyWidth, bodyTop } = useViewport()
  const [isMobile, setIsMobile] = useState(false)
  const [, setIsSD] = useState(false)

  useLayoutEffect(() => {
    if (bodyWidth < responsive.mobileThreshold) setIsMobile(true);
    else setIsMobile(false);

    if ((bodyWidth > responsive.mobileThreshold) && (bodyWidth < responsive.SDthreshold)) setIsSD(true)
    else setIsSD(false)
  }, [bodyWidth, bodyTop])

  const changeStep = (data: TsignInStep) => {
    setStep(data)
  }

  // useGoogleOneTapLogin({
  //   onSuccess: credentialResponse => {
  //     if (credentialResponse.credential) {
  //       const credential = credentialResponse.credential
  //       const segment = credential.split('.')
  //       //const parsed = JSON.parse(Buffer.from(segment[1], 'base64').toString('utf8'))
  //       const parsed = JSON.parse(atob(segment[1]))
  //       console.log(parsed.email)

  //       dispatch(googleLogin({ google_token: credential  }))
  //       .catch((error: any) => {
  //         console.log(error)
  //         // dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
  //       })
  //     }
  //   },
  //   onError: () => {
  //     console.log('Login Failed');
  //   },
  // })


  return(
    <AuthComponentContainer
      isMobile={isMobile}
    >
      <Card>
        {/* { isMobile && <Logo src={logo} /> } */}
        { step === 'login' && <Login changeStep={changeStep} /> }
        { step === 'resetPassword' && <ResetPassword changeStep={changeStep} /> }
        { step === 'resetPasswordSuccess' && <ResetPasswordSuccess changeStep={changeStep}/> }
        { step === 'signup' && <Register changeRegisterStep={changeStep}/>}
        { step === 'verificacion' &&  <Verificacion changeRegisterStep={changeStep}/> }
        { step === 'signUpSucces' &&  <SignUpSuccess changeRegisterStep={changeStep}/> }

      </Card>

    </AuthComponentContainer>
  )
}

interface TauthModalContainer { isMobile: boolean }
const AuthComponentContainer = styled.div<TauthModalContainer>`
  border-radius: 10px;
  margin-top: 90px;
  ${(args) => !args.isMobile && `
    display: flex;
    flex-flow: row nowrap;  
    background-color: white;
    gap: 30px;
  `}
`

const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 250px;
  max-width: 350px;
  margin: 0px auto 0px auto;
  border-radius: 10px;
`
const Logo = styled.img`
  width: 200px;
  margin-top: 10px;
` 