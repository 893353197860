import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import fetch, { Headers, Request, Response } from 'cross-fetch';
//import AbortController from 'abort-controller';

global.fetch = fetch as any;
global.Headers = Headers as any;
global.Request = Request as any;
global.Response = Response as any;
//global.AbortController = AbortController;

const BACKEND_URL = 'https://api.vcardsbusiness.net';

export const negocioApi =  createApi({
  reducerPath: 'negocioApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}`,
    fetchFn: fetch,
  }),
  endpoints: (builder) => ({

    getNegocio: builder.query({
      query: (args) => {

        let url = '/negocio/'
        if (args.negocioId) url += `${args.negocioId}`
        return {
          url,
        }
      }
    }),

  })
})

export const { useGetNegocioQuery } = negocioApi