import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'

import { useGetCorporacionQuery } from '../../services/negocios/queryApi'
import { TcorpDataItem, TcorporacionesData  } from '../../services/negocio/negociosApiTypes'
import { useLocation, useParams, useRouteLoaderData,  } from 'react-router-dom'
import { setCorpId, setCorpName } from '../../services/corporacion/corporacionSlice'

import CorporacionDataRow from '../negocioRow/corporacionDataRow'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'

import facebook from '../../assets/icons/social/facebook.png'
import instagram from '../../assets/icons/social/instagram.png'
import tiktok from '../../assets/icons/social/tiktok.png'
import youtube from '../../assets/icons/social/youtube.png'
import whatsapp from '../../assets/icons/social/whatsapp.png'

import linkedin from '../../assets/icons/social/linkedin.png'
import web from '../../assets/icons/social/www.png'
import telegram from '../../assets/icons/social/telegram.png'

import pinterest from '../../assets/icons/social/pinterest.png'
import twitter from '../../assets/icons/social/twitter.png'
import email from '../../assets/icons/social/email.png'

export default function CorporacionesDataList() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const params = useParams()
  const { app } = useConfig()
  const [ negocios, setNegocios ] = useState<TcorporacionesData|undefined>(undefined)

  const [ corp, setCorp ] = useState<TcorpDataItem | undefined>(undefined)
  const [ sucursales, setSucursales ] = useState<TcorpDataItem[]|undefined>(undefined)
  const [ personal, setPersonal ] = useState<TcorpDataItem[]|undefined>(undefined)

  const buscador = useAppSelector((state: TrootState) => state.buscador)
  const position = useAppSelector((state: TrootState) => state.position)
  const corporacion = useAppSelector((state: TrootState) => state.corporacion)

  const { data, error, isFetching, isLoading, refetch } = useGetCorporacionQuery({
    search: buscador.searchText !== '' ? buscador.searchText : undefined,
    corp_id: params.corporacion_id
  } , {
    pollingInterval: 0
  })

  useEffect(() => {
    if (data) {
      console.log(data)
      setNegocios(data)

      setCorp(data.corp)
      dispatch(setCorpId(data.corp.corp_id))
      dispatch(setCorpName(data.corp.nombre))

      setSucursales(data.items.filter((item:any) => item.categoria === 'sucursal'))
      setPersonal(data.items.filter((item:any) => item.categoria === 'personal'))
    }
  }, [data])

  return(
    <Container
      isMobile={location.pathname}
    >
      <div style={{ minHeight: '75px', minWidth: '100%'}} />

      <CorporacionInfo>

        <CorpThumb>
          <CorpImgContainer>
            <CorpImg src={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/corporaciones/${corporacion.corp_id}/thumb_150x150.jpg`} />
          </CorpImgContainer>

          <Side>
            <CorpTitle> { corporacion.corp_name } </CorpTitle>
            <CorpSubtitle> { corp?.desc ?? '' } </CorpSubtitle>
          </Side>
        </CorpThumb>

        <SocialLinks>
          { corp && corp.phone_negocio && 
            <SocialLink href={  `https://wa.me/591${corp.phone_negocio}`  } target="_blank">
              <Social src={whatsapp}/>
            </SocialLink>
          }

          { corp && corp.tiktok && 
            <SocialLink href={ corp.tiktok ? corp.tiktok : undefined} target="_blank">
              <Social src={tiktok}/>
            </SocialLink>
          }

          { corp && corp.facebook && 
            <SocialLink href={ corp.facebook ? corp.facebook : undefined} target="_blank">
              <Social src={facebook}/>
            </SocialLink>
          }

          { corp && corp.instagram && 
            <SocialLink href={ corp.instagram ? corp.instagram : undefined} target="_blank">
              <Social src={instagram}/>
            </SocialLink>
          }

          { corp && corp.youtube && 
            <SocialLink href={ corp.youtube ? corp.youtube : undefined} target="_blank">
              <Social src={youtube}/>
            </SocialLink>
          }

          { corp && corp.linkedin && 
            <SocialLink href={ corp.linkedin ? corp.linkedin : undefined} target="_blank">
              <Social src={linkedin}/>
            </SocialLink>
          }

          { corp && corp.web && 
            <SocialLink href={ corp.web ? corp.web : undefined} target="_blank">
              <Social src={web}/>
            </SocialLink>
          }

          { corp && corp.telegram && 
            <SocialLink href={ corp.telegram ? `https://t.me/${corp.telegram}` : undefined} target="_blank">
              <Social src={telegram}/>
            </SocialLink>
          }

          { corp && corp.pinterest && 
            <SocialLink href={ corp.pinterest ? corp.pinterest : undefined} target="_blank">
              <Social src={pinterest}/>
            </SocialLink>
          }

          { corp && corp.twitter && 
            <SocialLink href={ corp.twitter ? corp.twitter : undefined} target="_blank">
              <Social src={twitter}/>
            </SocialLink>
          }

          { corp && corp.email && 
            <SocialLink href={ corp.email ? `mailto:${corp.email}` : undefined} target="_blank">
              <Social src={email}/>
            </SocialLink>
          }
        </SocialLinks>

      </CorporacionInfo>

      <CategoriaTitle> Colaboradores </CategoriaTitle>
      <Ruler/> 

      {  personal && personal.map((negocio: TcorpDataItem, idx) => (  
        <CorporacionDataRow
          { ...negocio }
          key={`${idx}_personal_negocio.corp_id`}
        />
      ))}

      <CategoriaTitle> Sucursales </CategoriaTitle>
      <Ruler/> 

      {  sucursales && sucursales.map((negocio: TcorpDataItem, idx) => (  
        <CorporacionDataRow
          { ...negocio }
          key={`${idx}_sucursal_negocio.corp_id`}
        />
      ))}



      <div style={{ minHeight: '75px', minWidth: '100%'}} />

    </Container>
  )
}

interface TnegociosListCont { isMobile: string }
const Container = styled.div<TnegociosListCont>`
  display: flex;
  flex-flow: column nowrap;
 
  z-index: -10;
  width: 100%;
  
  ${(args) => args.isMobile === '/' && `
    padding-bottom: 150px;
  `}
  color: white;
`

const CategoriaTitle = styled.div`
  color: black;
  font-family: nunito;
  font-weight: 600;
  margin-left: 25px;
  margin-top: 10px; 
`
const Ruler = styled.div`
  margin-left: 25px;
  border-bottom: 1px solid gray;
  width: 150px;
`

const CorporacionInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 10px 0px;
`
const CorpImgContainer = styled.div`
  align-self: center;  
`
  const CorpThumb = styled.div`
    display: flex;
    margin-left: 20px;
    gap: 20px;
  `
    const CorpImg = styled.img`
      border-radius: 5px;
      width: 75px;
    `
    const CorpTitle = styled.div`
      font-family: Nunito, sans-serif;
      font-weight: 700;
      color: black;
    `

  const SocialLinks = styled.div`
    margin: 20px 0px 10px 15px;
  `
  const SocialLink = styled.a`
    display: inline;
    padding: 10px;
    margin: 0px;
    text-decoration: none;
  `

  const Social = styled.img`
    height: 40px;
  `

  const Side = styled.div`
    
  `
  const CorpSubtitle = styled.div`
    color: black;
    font-family: nunito;
    font-weight: 500;
    font-size: 14px;
  `