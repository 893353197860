import React from 'react'
import styled from 'styled-components'

import soonImage from '../../assets/icons/404/construction.png'

export default function Promos() {

  return(
    <Container>
      <MainText>
        Publica tu anuncio con nosotros
      </MainText>

      <MaingImage src={soonImage} />

      <SoonText>
        Muy Pronto...
      </SoonText>

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  height: 100vh;
`

const MainText = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 600;
`

const MaingImage = styled.img`
  height: 150px;
  margin: 50px 0px;
`

const SoonText = styled.div`
  font-family: nunito, sans-serif;
  font-weight: 600;
`