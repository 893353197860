import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TinitialState } from "./tarjetaTypes";
import { TnegocioItem } from "../negocio/negociosApiTypes";

const initialState: TinitialState = {
  negocioId: undefined,
  data: undefined,
}

const tarjetaSlice = createSlice({
  name: 'tarjeta',
  initialState,
  reducers: {
    setNegocioId(state, action: PayloadAction<string>) {
      state.negocioId = action.payload
    },
    cleanNegocio(state) {
      state.negocioId = undefined
      state.data = undefined
    },
    setNegociData(state, action: PayloadAction<TnegocioItem>) {
      state.data = action.payload
    }
  }
})

export const {
  setNegocioId,
  cleanNegocio,
  setNegociData,
} = tarjetaSlice.actions

export default tarjetaSlice.reducer