import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setSimpleData, setUpdating, setAlreadyEdited, cleanSlice } from '../../../../services/editNegocio/editNegocioSlice'
import { updateNegocioInfo } from '../../../../services/editNegocio/api'

import loading from '../../../../assets/gifs/infinity-loader.gif'


export default function EditSocial() {
  const dispatch = useAppDispatch()
  const [ edited, setEdited ] = useState<boolean>(false)

  const editing = useAppSelector((state: TrootState) => state.editNegocio)
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta.data)

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    let data: { prop: undefined|string, value: string | undefined } = { prop: undefined, value: undefined }

    if (event.target.name === 'negocio-facebook') {
      if (value !== tarjeta?.facebook) data = { prop: 'facebook', value: value }
      else data = { prop: 'facebook', value: undefined }
    }

    if (event.target.name === 'negocio-instagram') {
      if (value !== tarjeta?.instagram) data = { prop: 'instagram', value: value }
      else data = { prop: 'instagram', value: undefined }
    }

    if (event.target.name === 'negocio-tiktok') {
      if (value !== tarjeta?.tiktok) data = { prop: 'tiktok', value: value }
      else data = { prop: 'tiktok', value: undefined }
    }

    if (event.target.name === 'negocio-youtube') {
      if (value !== tarjeta?.youtube) data = { prop: 'youtube', value: value }
      else data = { prop: 'youtube', value: undefined }
    }

    if (event.target.name === 'negocio-linkedin') {
      if (value !== tarjeta?.linkedin) data = { prop: 'linkedin', value: value }
      else data = { prop: 'linkedin', value: undefined }
    }

    if (event.target.name === 'negocio-web') {
      if (value !== tarjeta?.web) data = { prop: 'web', value: value }
      else data = { prop: 'web', value: undefined }
    }

    if (event.target.name === 'negocio-telegram') {
      if (value !== tarjeta?.telegram) data = { prop: 'telegram', value: value }
      else data = { prop: 'telegram', value: undefined }
    }

    if (event.target.name === 'negocio-pinterest') {
      if (value !== tarjeta?.pinterest) data = { prop: 'pinterest', value: value }
      else data = { prop: 'pinterest', value: undefined }
    }

    if (event.target.name === 'negocio-twitter') {
      if (value !== tarjeta?.twitter) data = { prop: 'twitter', value: value }
      else data = { prop: 'twitter', value: undefined }
    }

    if (event.target.name === 'negocio-email') {
      if (value !== tarjeta?.email) data = { prop: 'email', value: value }
      else data = { prop: 'email', value: undefined }
    }

    if (data.prop !== undefined) {
      dispatch(setSimpleData({ group: 1, prop: data.prop, value: data.value }))
    }
  }


  useEffect(() => {
    const edited = editing.dataSteps[1].map((item) => item.edited)

    if (edited.includes(true)) setEdited(true)
    else setEdited(false)
  }, [editing])

  function saveDataChanges() {
    const filtered = editing.dataSteps[1].filter((item) => item.edited === true)
    const payload = filtered.reduce((acc: any, item: any) => {
      let { prop, value } = item

      return { ...acc, [prop]: value }
    }, {})

    dispatch(setUpdating('updating'))
    updateNegocioInfo({ negocio_id: editing.editing, ...payload })
    .then((resp) => {
      dispatch(setAlreadyEdited(1))
      dispatch(setUpdating('updated'))      
    })
    .catch((err) => {
      console.log(err)
      dispatch(setUpdating('initial'))
    })
  }


  return(
    <Container>

      <InputsOverflow>
        <InputContainer>
          <Label> Facebook </Label>
          <InputNegocio
            type='text'
            name='negocio-facebook'
            placeholder="Facebook"
            //value={publicar.data.facebook ? publicar.data.facebook : ''}
            defaultValue={tarjeta?.facebook ? tarjeta.facebook : undefined}
            setted={ tarjeta?.facebook ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Instagram </Label>
          <InputNegocio
            type='text'
            name='negocio-instagram'
            placeholder="Instagram"
            //value={publicar.data.instagram ? publicar.data.instagram : ''}
            defaultValue={ tarjeta?.instagram ? tarjeta.instagram : undefined}
            setted={ tarjeta?.instagram ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> TikTok </Label>
          <InputNegocio
            type='text'
            name='negocio-tiktok'
            placeholder="TikTok"
            //value={publicar.data.tiktok ? publicar.data.tiktok : ''}
            defaultValue={ tarjeta?.tiktok ? tarjeta.tiktok : undefined}
            setted={ tarjeta?.tiktok ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Youtube </Label>
          <InputNegocio
            type='text'
            name='negocio-youtube'
            placeholder="Youtube"
            //value={publicar.data.youtube ? publicar.data.youtube : ''}
            defaultValue={ tarjeta?.youtube ? tarjeta.youtube : undefined}
            setted={ tarjeta?.youtube ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> LinkedIn </Label>
          <InputNegocio
            type='text'
            name='negocio-linkedin'
            placeholder="LinkedIn"
            //value={publicar.data.linkedin ? publicar.data.linkedin : ''}
            defaultValue={ tarjeta?.linkedin ? tarjeta.linkedin : undefined}
            setted={ tarjeta?.linkedin ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Pagina Web </Label>
          <InputNegocio
            type='text'
            name='negocio-web'
            placeholder="Pagina Web"
            //value={publicar.data.web ? publicar.data.web : ''}
            defaultValue={ tarjeta?.web ? tarjeta.web : undefined}
            setted={ tarjeta?.web ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Telegram </Label>
          <InputNegocio
            type='text'
            name='negocio-telegram'
            placeholder="Telegram (+591 xxxxxxxx)"
            //value={publicar.data.telegram ? publicar.data.telegram : ''}
            defaultValue={ tarjeta?.telegram ? tarjeta.telegram : undefined}
            setted={ tarjeta?.telegram ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Pinterest </Label>
          <InputNegocio
            type='text'
            name='negocio-pinterest'
            placeholder="Pinterest"
            //value={publicar.data.pinterest ? publicar.data.pinterest : ''}
            defaultValue={ tarjeta?.pinterest ? tarjeta.pinterest : undefined}
            setted={ tarjeta?.pinterest ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Twitter </Label>
          <InputNegocio
            type='text'
            name='negocio-twitter'
            placeholder="Twitter"
            //value={publicar.data.twitter ? publicar.data.twitter : ''}
            defaultValue={ tarjeta?.twitter ? tarjeta.twitter : undefined}
            setted={ tarjeta?.twitter ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Email Negocio </Label>
          <InputNegocio
            type='text'
            name='negocio-email'
            placeholder="Email"
            //value={publicar.data.email ? publicar.data.email : ''}
            defaultValue={ tarjeta?.email ? tarjeta.email : undefined}
            setted={ tarjeta?.email ? true : false}
            onChange={inputChange}
          />
        </InputContainer>
      </InputsOverflow>

      <Botonera>
        { editing.updating === 'updating' ?
            <LoadingContainer>
              <LoadingSpinner src={loading}/>
            </LoadingContainer>
          :
            <Save
              edited={edited}
              disabled={!edited}
              onClick={() => {
                saveDataChanges()
              }}
            >
              Guardar
            </Save>
        }

        <Cancel
          onClick={() => {
            dispatch(cleanSlice())
          }}
        >
          Cerrar
        </Cancel>

      </Botonera>


    </Container>
  )
}

const Container = styled.div``
const InputsOverflow = styled.div`
  max-height: calc(80vh - 160px);
  overflow: auto;
`

interface Tselect { setted: boolean }
const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 11px;
    margin-left: 0px;
    font-family: Roboto, sans-serif;
    
  `

  const InputNegocio = styled.input<Tselect>`
    font-family: Nunito, sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: 300px;
    margin: 0px 0px 15px 0px;
    padding: 0px 5px 0px 5px;
    border: none;
    border-bottom: solid 1px rgba(219,219,219,1);
    
    &:focus {
      outline: none;
      border-bottom: solid 2px rgba(0,0,0,1);
    }
    ${(args) => !args.setted && `
      
    `}
    ${(args) => args.setted && `
      color: black;
    `}
  `


const Botonera = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid rgb(209, 209, 209);
`
  const Button = styled.button`
    width: 100px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    font-family: Nunito, sans-serif;
    font-weight: 600;
  `

  interface Tsave { edited: boolean }
  const Save = styled(Button)<Tsave>`
    color: rgb(219,219,219);
    ${(args) => args.edited && `
      color: #ff2a7f;
    `}
  `
  const Cancel = styled(Button)``

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  `
  const LoadingSpinner = styled.img`
    height: 25px;
  `