import React from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import { setDistrito, setNombre, setTipo, setNegocioPhone, setLocalidad } from '../crearNegocioSlice';

export default function NegocioData() {
  const dispatch = useAppDispatch()
  const publicar = useAppSelector((state: TrootState) => state.crearNegocio);

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'negocio-nombre')
      dispatch(setNombre(event.target.value))

    if (event.target.name === 'negocio-tipo')
      dispatch(setTipo(event.target.value))

    if (event.target.name === 'negocio-distrito')
      dispatch(setDistrito(Number(event.target.value)))

    if (event.target.name === 'negocio-localidad')
      dispatch(setLocalidad(event.target.value))

    if (event.target.name === 'negocio-phono')
      dispatch(setNegocioPhone(event.target.value))
  }

  return(
    <NegocioDataContainer>

      <Title> Datos del Negocio </Title>
      
      <InputContainer>
        <Label>Nombre del Negocio <Opcional>(Ejem. Kjaras Don Roque)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-nombre'
          placeholder="Nombre del Negocio"
          value={publicar.data.nombre ? publicar.data.nombre : ''}
          defaultValue={publicar.data.nombre ? publicar.data.nombre : undefined}
          setted={publicar.data.nombre ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Tipo de Negocio <Opcional>(Ejem. Restaurant)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-tipo'
          placeholder="Tipo de Negocio"
          value={publicar.data.tipo ? publicar.data.tipo : ''}
          defaultValue={publicar.data.tipo ? publicar.data.tipo : undefined}
          setted={publicar.data.tipo ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

        {/* <InputNegocio
          type='text'
          name='negocio-distrito'
          placeholder="Distrito de la ciudad"
          value={publicar.data.distrito ? publicar.data.distrito : ''}
          defaultValue={publicar.data.distrito ? publicar.data.distrito : undefined}
          setted={publicar.data.distrito ? true : false}
          onChange={inputChange}
        /> */}

      <InputContainer>
        <Label>Barrio o Localidad <Opcional>(Opcional)</Opcional> </Label>
        <InputNegocio
          type='text'
          name='negocio-localidad'
          placeholder="(ejem. Plan 3000, Satelite, Warnes, La Guardia)"
          value={publicar.data.localidad ? publicar.data.localidad : ''}
          defaultValue={publicar.data.localidad ? publicar.data.localidad : undefined}
          setted={publicar.data.localidad ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Numero telefonico del negocio </Label>
        <InputNegocio
          type='text'
          name='negocio-phono'
          placeholder="70012345"
          value={publicar.data.phone_negocio ? publicar.data.phone_negocio : ''}
          defaultValue={publicar.data.phone_negocio ? publicar.data.phone_negocio : undefined}
          setted={publicar.data.phone_negocio ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

    </NegocioDataContainer>
  )
}

interface Tselect { setted: boolean }
const NegocioDataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`

const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 13px;
  `
  const Opcional = styled.span`
    font-size: 10px;
    color: gray;
  `

  const InputNegocio = styled.input<Tselect>`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    width: 300px;
    margin: 5px 0px 0px 0px;
    padding: 5px 5px 5px 5px;
    border: solid 1px gray;
    border-radius: 5px;
    border-color: #00000088;
    &:focus {
      outline: none;
    }
    ${(args) => !args.setted && `
      border-color: #00000066;
    `}
    ${(args) => args.setted && `
      color: black;
      border-color: black;
    `}
  `

const Title = styled.div`
  font-family: Nunito;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
`