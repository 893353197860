import React from 'react'
import styled from 'styled-components'
import { TnegocioItem } from '../../../../services/negocio/negociosApiTypes'
import useConfig from '../../../../hooks/useConfig'

import facebook from '../../../../assets/icons/social/facebook.png'
import instagram from '../../../../assets/icons/social/instagram.png'
import tiktok from '../../../../assets/icons/social/tiktok.png'
import youtube from '../../../../assets/icons/social/youtube.png'
import whatsapp from '../../../../assets/icons/social/whatsapp.png'

import linkedin from '../../../../assets/icons/social/linkedin.png'
import web from '../../../../assets/icons/social/www.png'
import telegram from '../../../../assets/icons/social/telegram.png'

import pinterest from '../../../../assets/icons/social/pinterest.png'
import twitter from '../../../../assets/icons/social/twitter.png'
import email from '../../../../assets/icons/social/email.png'

import go from '../../../../assets/icons/tarjeta/go.png'


import pdf from '../../../../assets/icons/tarjeta/pdf-svgrepo-com.png'
import download from '../../../../assets/icons/tarjeta/download-square-svgrepo-com.png'


interface Tprops { negocio: TnegocioItem }
export default function SocialLinks(props: Tprops) {
  const { negocio } = props
  const { app } = useConfig()

  return(
    <SocialContainer>

      { negocio.catalogo &&
        <Catalogo
          href={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/catalogo.pdf`}
          target="_blank"
          download
        >
          <Social src={pdf}/>
          <CatalogoText> Descargar Catalogo </CatalogoText>
          <SocialGo src={download} />
        </Catalogo>
      }

      { negocio.phone_negocio && 
        <SocialLink href={  `https://wa.me/591${negocio.phone_negocio}`  } target="_blank">
          <Social src={whatsapp}/>
          <SocialText> Whatsapp </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { !negocio.phone_negocio && negocio.phone_contacto && 
        <SocialLink href={ `https://wa.me/591${negocio.phone_contacto}` } target="_blank">
          <Social src={whatsapp}/>
          <SocialText> Telefono </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.tiktok && 
        <SocialLink href={ negocio.tiktok ? negocio.tiktok.match('https://') ? negocio.tiktok : `https://${negocio.tiktok}` : undefined} target="_blank">
          <Social src={tiktok}/>
          <SocialText> TikTok </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.facebook && 
        <SocialLink href={ negocio.facebook ? negocio.facebook : undefined} target="_blank">
          <Social src={facebook}/>
          <SocialText> Facebook </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.instagram && 
        <SocialLink href={ negocio.instagram ? negocio.instagram : undefined} target="_blank">
          <Social src={instagram}/>
          <SocialText> Instagram </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.youtube && 
        <SocialLink href={ negocio.youtube ? negocio.youtube : undefined} target="_blank">
          <Social src={youtube}/>
          <SocialText> Youtube </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.linkedin && 
        <SocialLink href={ negocio.linkedin ? negocio.linkedin : undefined} target="_blank">
          <Social src={linkedin}/>
          <SocialText> LinkedIn </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.web && 
        <SocialLink href={ negocio.web ? negocio.web : undefined} target="_blank">
          <Social src={web}/>
          <SocialText> WebSite </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.telegram && 
        <SocialLink href={ negocio.telegram ? `https://t.me/${negocio.telegram}` : undefined} target="_blank">
          <Social src={telegram}/>
          <SocialText> Telegram </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.pinterest && 
        <SocialLink href={ negocio.pinterest ? negocio.pinterest : undefined} target="_blank">
          <Social src={pinterest}/>
          <SocialText> Pinterest </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.twitter && 
        <SocialLink href={ negocio.twitter ? negocio.twitter : undefined} target="_blank">
          <Social src={twitter}/>
          <SocialText> Twitter </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }

      { negocio.email && 
        <SocialLink href={ negocio.email ? `mailto:${negocio.email}` : undefined} target="_blank">
          <Social src={email}/>
          <SocialText> Email </SocialText>
          <SocialGo src={go} />
        </SocialLink>
      }
        
      
      
    </SocialContainer>
  )
}


const SocialContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  
  margin: auto;
  margin-top: 20px;
  max-height: calc(85vh - 420px);
  overflow: auto;
  gap: 10px;

  padding-top: 10px;

  
  
  background:
    /* Shadow Cover TOP */
    linear-gradient(
      rgb(73, 89, 99) 10%,
      rgba(73, 89, 99, 0)
    ) center top,


    /* Shadow TOP */
    radial-gradient(
      farthest-side at 5% 0,
      rgba(41, 50, 56, 0.5),
      rgba(0, 0, 0, 0)
    ) center top,
    
    /* Shadow BOTTOM */
    radial-gradient(
      farthest-side at 50% 100%,
      rgba(41, 50, 56, 0.5),
      rgba(0, 0, 0, 0)
    ) center bottom;

    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

`
const SocialLink = styled.a`
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  
  padding: 10px;
  margin: auto;
  background-color: #F9F9F8;
  border-radius: 20px;
  margin: 0px;
  text-decoration: none;
`
  const Social = styled.img`
    height: 30px;
  `
  const SocialText = styled.div`
    text-decoration: none;
    color: #434343;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 14px;
    
  `
  const SocialGo = styled.img`
    height: 30px;
  `

const Catalogo = styled.a`
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  
  padding: 10px;
  margin: auto;
  background-color: #e2574cff;

  border-radius: 20px;
  margin: 0px;
  text-decoration: none;
`

  const CatalogoText = styled.div`
    text-decoration: none;
    color: white;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 14px;
  `