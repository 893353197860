import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import GmapWrapper from '../../gmaps/googleMapsWrapper'
import { setLocation } from '../crearNegocioSlice'

export default function LocationData() {
  const dispatch = useAppDispatch()
  const [ gpsAvailable, setGpsAvailable ] = useState<boolean>(false)
  const [ posX, setPosX ] = useState<number|undefined>(undefined)
  const [ posY, setPosY ] = useState<number|undefined>(undefined)
  const [ message, setMessage ] = useState<string|undefined>(undefined)

  const publicar = useAppSelector((state: TrootState) => state.crearNegocio)

  useEffect(() => {
    if ("geolocation" in navigator) {
      setGpsAvailable(true);
    } else {
      setGpsAvailable(false);
    }
  }, [])

  function getCurrentPosition() {
    try {
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        setPosY(position.coords.latitude);
        setPosX(position.coords.longitude);

        if (position.coords.latitude && position.coords.longitude) {
          dispatch(setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }))
        }
      });
    } catch(error) {
      setMessage(JSON.stringify(error, null, 4));
    }
  }

  useEffect(() => {
    if (!publicar.data.lat && !publicar.data.lng) {
      getCurrentPosition()
    }
  }, [])

  return(
    <LocationContainer>

      <Title> Ubicacion del Negocio </Title>

      <GmapWrapper/>

      <Row>
        <CurrentPosition>
          <Pos>
            <b> Long: </b>
            { publicar.data.lng ? publicar.data.lng.toFixed(5) : 'N/A' }
          </Pos>

          <Pos>
            <b> Lat: </b>
            { publicar.data.lat ? publicar.data.lat.toFixed(5) : 'N/A' }
          </Pos>
        </CurrentPosition>

        <GPSContainer>
          <Available isAvailable={gpsAvailable}>
            GPS: <b>{ gpsAvailable ? 'Disponible' : 'NO Disponible' }</b>
          </Available>

          <div className='crearng-position-button-container'>
            <button
              className='crearng-position-button'
              type='button'
              onClick={() => {getCurrentPosition()}}
            >
              Mi Ubicacion Actial
            </button>
          </div>
        </GPSContainer>
      </Row>

    </LocationContainer>
  )
}

const LocationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`

const Title = styled.div`
  font-family: Nunito;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
`

const CurrentPosition = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
`


const Pos = styled.div`
  font-size: 12px;
  margin: 0px;
`
const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: 10px;
`
const GPSContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
  interface isAvailable { isAvailable: boolean }
  const Available = styled.div<isAvailable>`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    color: #E73C37;
    ${(args) => args.isAvailable && `
      color: #50B2C0;
    `}
  `