import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TinitialState, Tfile } from './crearNegocioTypes'

import upload from '../../assets/icons/crear/upload.png'

const initialState: TinitialState = {
  step: 0,
  data: {
    nombre: undefined,
    tipo: undefined,
    distrito: undefined,
    localidad: undefined,
    negocio_id: undefined,
    phone_negocio: undefined,
  
    nombre_contacto: undefined,
    phone_contacto: undefined,
  
    lat: undefined,
    lng: undefined,
    
    facebook: undefined,
    instagram: undefined,
    tiktok: undefined,
    youtube: undefined,
    linkedin: undefined,

    web: undefined,
    telegram: undefined,
    pinterest: undefined,
    twitter: undefined,
    email: undefined,
    referer: undefined,
  },
  photos: [
    { dataUrl: upload, loaded: false }
  ]
}

const crearNegocioSlice = createSlice({
  name: 'crearNegocio',
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload
    },

    setPhoto(state, action: PayloadAction<Tfile>) {
      const photos = [...state.photos]

      let lastPhoto = photos.pop()
      lastPhoto = action.payload

      state.photos = [
        ...photos,
        lastPhoto,
        { dataUrl: upload,  loaded: false }
      ]
    },

    setDeletePhoto(state, action: PayloadAction<number>) {
      const photos = [...state.photos]

      photos.splice(action.payload, 1); // 2nd parameter means remove one item only
      state.photos = [
        ...photos,
      ]
    },

    setReferer(state, action: PayloadAction<string>) {
      state.data.referer = action.payload
    },
    setNombre(state, action: PayloadAction<string>) {
      state.data.nombre = action.payload
    },
    setTipo(state, action: PayloadAction<string>) {
      state.data.tipo = action.payload
    },
    setDistrito(state, action: PayloadAction<number>) {
      state.data.distrito = action.payload
    },
    setLocalidad(state, action: PayloadAction<string>) {
      state.data.localidad = action.payload
    },
    setNegocioId(state, action: PayloadAction<string>) {
      state.data.negocio_id = action.payload
    },
    setNegocioPhone(state, action: PayloadAction<string>) {
      state.data.phone_negocio = action.payload
    },
    setContactoNombre(state, action: PayloadAction<string>) {
      state.data.nombre_contacto = action.payload
    },
    setContactoPhone(state, action: PayloadAction<string>) {
      state.data.phone_contacto = action.payload
    },
    setLocation(state, action: PayloadAction<{ lat: number, lng: number }>) {
      state.data.lat = action.payload.lat
      state.data.lng = action.payload.lng
    },

    setFacebook(state, action: PayloadAction<string>) {
      state.data.facebook = action.payload
    },
    setInstagram(state, action: PayloadAction<string>) {
      state.data.instagram = action.payload
    },
    setTiktok(state, action: PayloadAction<string>) {
      state.data.tiktok = action.payload
    },
    setYoutube(state, action: PayloadAction<string>) {
      state.data.youtube = action.payload
    },
    setLinkedin(state, action: PayloadAction<string>) {
      state.data.linkedin = action.payload
    },

    setWeb(state, action: PayloadAction<string>) {
      state.data.web = action.payload
    },
    setTelegram(state, action: PayloadAction<string>) {
      state.data.telegram = action.payload
    },
    setPinterest(state, action: PayloadAction<string>) {
      state.data.pinterest = action.payload
    },
    setTwitter(state, action: PayloadAction<string>) {
      state.data.twitter = action.payload
    },
    setEmail(state, action: PayloadAction<string>) {
      state.data.email = action.payload
    },

    setInitialState(state) {
      state.step = initialState.step
      state.data = initialState.data
      state.photos = initialState.photos
    }

  }
})

export const {
  setReferer,
  setNombre,
  setTipo,
  setDistrito,
  setLocalidad,
  setNegocioId,
  setNegocioPhone,
  setContactoNombre,
  setContactoPhone,
  setLocation,
  setFacebook,
  setInstagram,
  setTiktok,
  setYoutube,
  setLinkedin,
  setWeb,
  setTelegram,
  setPinterest,
  setTwitter,
  setEmail,
  setStep,
  setPhoto,
  setDeletePhoto,
  setInitialState,
} = crearNegocioSlice.actions

export default crearNegocioSlice.reducer