import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setEnableAdmin, setDisableAdmin, setShowCrearNegocio } from '../../services/admin/adminSlice'

// import { useGoogleLogin, useGoogleOneTapLogin  } from '@react-oauth/google'
import { closeSession } from '../../services/auth/userSlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

import userDefault from '../../assets/icons/ui/user.png'
import { setNegocioId } from '../../services/tarjeta/tarjetaSlice'

import AuthComponent from '../../components/auth/auth'
import LastRegistered from './components/lastRegistered'
import CrearNegocioComponent from '../../components/crearNegocio/crearNegocio'


export default function MyAccount() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [, setSearchParam] = useSearchParams()

  const user = useAppSelector((state: TrootState) => state.user)
  const admin = useAppSelector((state: TrootState) => state.admin)

  function inputChange(event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) {
    if (event.target.name === 'user-adminMode') {
      const target = event.target as typeof event.target & { checked: boolean}
      if (target.checked) dispatch(setEnableAdmin())
      else dispatch(setDisableAdmin())
    }
  }

  function logOut() {
    dispatch(closeSession())
    .then((result: any) => {
      if (result.meta.requestStatus === 'fulfilled') {
        navigate('/')
      }
    })
  }

  return(
    <Container>
      { !user.data && <AuthComponent/> }

      {
        user.data &&
        <>
          <UserCard>

            <UserPic>
              <Picture src={userDefault} />
            </UserPic>

            <UserInfo>
              <Title> { user.data.given_name } { user.data.family_name }</Title>

              <Subtitle>
                { user.data ? user.data.email : 'loading' }
              </Subtitle>

              <CloseSession
                onClick={() => {
                  logOut()
                }}
              >
                Cerrar Sesión
              </CloseSession>
            </UserInfo>

          </UserCard>

          { user.data.role === 'admin' &&
            <Row>
              <LabelToogle htmlFor='user-adminMode'>
                Modo Administrador:
              </LabelToogle>
              <Toggle
                id='user-adminMode'
                name='user-adminMode'
                checked={admin.active ? true : false}
                onChange={inputChange}
              />
            </Row>
          }

          {/* Borrar? */}
          { user.data.role === 'admin' && admin.active &&            
            <CrearNegocio
              onClick={() => {
                dispatch(setShowCrearNegocio())
              }}
            >
              Crear negocio
            </CrearNegocio>
          }

          {/* USUARIO NORMAL OPCIONES */}
          {  (!user.data.negocios || user.data.negocios.length <= 4  ) && user.data.role === 'user' &&
            <CrearNegocio
              onClick={() => {
                dispatch(setShowCrearNegocio())
              }}
            >
              Registrar Mi Negocio
            </CrearNegocio>
          }

          { user.data.negocios && user.data.role === 'user' && 
            <LastRegistered/>
          }

          {/* ADMIN Y REGISTRADORES */}
          { (user.data.role === 'registrador' || user.data.role === 'admin') &&
            <>
              <CrearNegocio
                onClick={() => {
                  dispatch(setShowCrearNegocio())
                }}
              >
                Registrar Negocio
              </CrearNegocio>

              <RefererCodeContainer>
                <span> Codigo de referido </span>
                <h1>
                  { user.data.refererCode ?? 'XASKSA' }
                </h1>
              </RefererCodeContainer>

              <LastRegistered/>
            </>
          }

          {
            admin.crearNegocio && 
            <CrearNegocioComponent/>
          }

        </>
      }
    </Container>
  )
}

const Container = styled.div`
  margin-top: 65px;
  margin-bottom: 80px;
  height: calc(100vh - 145px);
  display: flex;
  flex-flow: column nowrap;
  
  align-items: center;
  padding: 0px 5%;
`
const UserCard = styled.div`
  background-color: #e3dac9;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 10px;
  margin-top: 20px;
`
  const UserPic = styled.div`
    width: 100px;
  `
  const Picture = styled.img`
    width: 100px;
  `

  const UserInfo = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  `
    const Title = styled.div`
      display: flex;
      width: 100%;
      justify-content: flex-start;
      font-family: Roboto;
      font-weight: 600;
    `
    const Subtitle = styled.div`
      margin-top: 10px;
      font-family: Nunito;
      font-size: 12px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `

    const CloseSession = styled.div`
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 14px;
      background-color: black;
      color: white;
      text-align: center;
      width: 150px;
      border-radius: 10px;
      align-self: center;
      margin-top: 20px;
    `

const LabelToogle = styled.label`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
`
const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  gap: 10px;
`

const CrearNegocio = styled.div`
  width: 90%;
  text-align: center;
  background-color: #dedede;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 60px;
  margin-bottom: 20px;
  padding: 5px 0px;
  box-shadow: 2px 2px #888888;
`

const VerMiNegocio = styled.div`
  width: 90%;
  text-align: center;
  background-color: #ffcc00;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 50px;
  padding: 5px 0px;
  box-shadow: 2px 2px #888888;
`

const RefererCodeContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`