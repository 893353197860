import React, { useState } from 'react'
import styled from 'styled-components'
import { deleteNegocio } from '../../../../services/negocio/api'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useConfig from '../../../../hooks/useConfig'
import Loader from '../../../loader/loader'

import ConfirmModal from '../../../modal/confirmModal'

import trash from '../../../../assets/icons/admin/trash.png'

export default function AdminOptions() {
  const [showModal, setShowModal] = useState(false)
  const config = useConfig()
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams,] = useSearchParams()
  const [deleting, setDeleting] = useState(false)

  const closeModal = () => setShowModal(false)

  function deleteNegocioFn(id: string|null) {
    console.log(id)
    if (typeof id === 'string') {
      deleteNegocio(id)
      .then((resp) => {
        setDeleting(false)
        setShowModal(false)
        navigate('/')
        window.location.replace(config.app.BACKEND_URL)
      })
    }
  }

  return(
    <>
      <Container>
        <AdminText> Opciones de Admin: </AdminText>
        <AdminButtons>
          <AdminButton>
            <AdminImgButton
              src={trash}
              onClick={() => {
                setShowModal(true)
              }}
            />
          </AdminButton>
        </AdminButtons>

        {
          showModal &&
          <ConfirmModal
            closeModal={closeModal}
            text="¿Esta seguro que desea eliminar este negocio?"
            confirmFunction={() => {
              setDeleting(true)
              deleteNegocioFn(params.negocio_id ? params.negocio_id : searchParams.get('negocio')  )
            }}
          />
        }
      </Container>

      { deleting &&
        <Loader/>
      }
    </>
  )
}

const Container = styled.div`
  width: 90%;
  margin: auto;
  border-radius: 10px;
  background-color: black;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
`
  const AdminText = styled.div`
    font-family: Nunito, sans-serif;
    font-size: 14px;
    color: white;
  `
  const AdminButtons = styled.div``
  const AdminButton = styled.div`
    border-radius: 5px;
    padding: 2px;
    background-color: white;
  `
  const AdminImgButton = styled.img`
    height: 25px;
  `