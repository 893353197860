export const filterOnlyAllowedProps = (payload: any, requiredProps: string[]) => {
  const filteredPayload = Object.fromEntries(Object.entries(payload).filter(([prop, value]) => {
      if (requiredProps.includes(prop)) {
          return true
      } else {
          return false
      }
  }))

  return filteredPayload;
}


export const getPositions = () => { return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(function(position) {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude  
          })      
      })
    } catch(error) {
      console.log(`NegociosList (getPositions) : ${error}`)
      reject(error)
    }
  })
}