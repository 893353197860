import React from "react"
import { Outlet, useSearchParams } from "react-router-dom"
import Header from "../components/header/header"
import MainMenu from "../components/menu/menu"
import Tarjeta from "../components/tarjeta/tarjeta"

import { setHiddeCrearNegocio } from "../services/admin/adminSlice"
import CrearNegocio from "../components/crearNegocio/crearNegocio"

import { TrootState, useAppDispatch, useAppSelector } from "./store"

export default function Layout() {
  const dispatch = useAppDispatch()
  const [ searchParams ] = useSearchParams()
  const admin = useAppSelector((state: TrootState) => state.admin)

  return(
    <>
      <Header/>
      <Outlet/>
      <MainMenu/>

      {/* {
        admin.crearNegocio &&
        <CrearNegocio/>
      } */}
      

      { searchParams.get('negocio') &&
        <Tarjeta/>
      }

    </>
  )
}