import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setDistrito, setNombre, setTipo, setNegocioPhone, setLocalidad } from '../../../crearNegocio/crearNegocioSlice'
import { setSimpleData, cleanSlice, setUpdating, setAlreadyEdited } from '../../../../services/editNegocio/editNegocioSlice'
import { updateNegocioInfo } from '../../../../services/editNegocio/api'

import loading from '../../../../assets/gifs/infinity-loader.gif'


export default function EditInfo() {
  const dispatch = useAppDispatch()
  const [ edited, setEdited ] = useState<boolean>(false)

  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta.data )
  const editing = useAppSelector((state: TrootState) => state.editNegocio )

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target
    let data: { prop: undefined|string, value: string | undefined } = { prop: undefined, value: undefined }

    if (name === 'negocio-nombre') {
      if (value !== tarjeta?.nombre) data = { prop: 'nombre', value: value }
      else data = { prop: 'nombre', value: undefined }
    }

    if (name === 'negocio-tipo') {
      if (value !== tarjeta?.tipo) data = { prop: 'tipo', value: value }
      else data = { prop: 'tipo', value: undefined }
    }

    if (event.target.name === 'negocio-localidad') {
      if (value !== tarjeta?.localidad) data = { prop: 'localidad', value: value }
      else data = { prop: 'localidad', value: undefined }
    }

    if (event.target.name === 'negocio-phono') {
      if (value !== tarjeta?.phone_negocio) data = { prop: 'phone_negocio', value: value }
      else data = { prop: 'phone_negocio', value: undefined }
    }


    if (data.prop !== undefined) {
      dispatch(setSimpleData({ group: 0, prop: data.prop, value: data.value }))
    }
  }

  useEffect(() => {
    const edited = editing.dataSteps[0].map((item) => item.edited)

    if (edited.includes(true)) setEdited(true)
    else setEdited(false)
  }, [editing])

  function saveDataChanges() {
    const filtered = editing.dataSteps[0].filter((item) => item.edited === true)
    const payload = filtered.reduce((acc: any, item: any) => {
      let { prop, value } = item

      return { ...acc, [prop]: value }
    }, {})

    dispatch(setUpdating('updating'))
    updateNegocioInfo({ negocio_id: editing.editing, ...payload })
    .then((resp) => {
      dispatch(setAlreadyEdited(0))
      dispatch(setUpdating('updated'))      
    })
    .catch((err) => {
      console.log(err)
      dispatch(setUpdating('initial'))
    })
  }

  return(
    <Container>

      <InputsOverflow>
        <InputContainer>
          <Label> Nombre del Negocio: </Label>

          <InputNegocio
            type='text'
            name='negocio-nombre'
            placeholder="Nombre del Negocio"
            // value={publicar.data.nombre ? publicar.data.nombre : ''}
            defaultValue={tarjeta?.nombre ? tarjeta.nombre : undefined}
            setted={tarjeta?.nombre ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Tipo de Negocio: </Label>
          <InputNegocio
            type='text'
            name='negocio-tipo'
            placeholder="Tipo de Negocio"
            // value={publicar.data.tipo ? publicar.data.tipo : ''}
            defaultValue={ tarjeta?.tipo ? tarjeta.tipo : undefined}
            setted={ tarjeta?.tipo ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label> Barrio o Localidad: </Label>
          <InputNegocio
            type='text'
            name='negocio-localidad'
            placeholder="(ejem. Plan 3000, Satelite, Warnes, La Guardia)"
            //value={ publicar.data.localidad ? publicar.data.localidad : ''}
            defaultValue={tarjeta?.localidad ? tarjeta.localidad : undefined}
            setted={ tarjeta?.localidad ? true : false}
            onChange={inputChange}
          />
        </InputContainer>

        <InputContainer>
          <Label>Numero telefonico del negocio </Label>
          <InputNegocio
            type='text'
            name='negocio-phono'
            placeholder="70012345"
            //value={publicar.data.phone_negocio ? publicar.data.phone_negocio : ''}
            defaultValue={ tarjeta?.phone_negocio ? tarjeta.phone_negocio : undefined}
            setted={ tarjeta?.phone_negocio ? true : false}
            onChange={inputChange}
          />
        </InputContainer>
      </InputsOverflow>

      <Botonera>
        { editing.updating === 'updating' ?
            <LoadingContainer>
              <LoadingSpinner src={loading}/>
            </LoadingContainer>
          :
            <Save
              edited={edited}
              disabled={!edited}
              onClick={() => {
                saveDataChanges()
              }}
            >
              Guardar
            </Save>
        }

        <Cancel
          onClick={() => {
            dispatch(cleanSlice())
          }}
        >
          Cerrar
        </Cancel>

      </Botonera>


    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-grow: 2;
`

const InputsOverflow = styled.div`
  max-height: calc(80vh - 160px);
  overflow: auto;
`


interface Tselect { setted: boolean }
const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 11px;
    margin-left: 0px;
    font-family: Roboto, sans-serif;
    
  `

  const InputNegocio = styled.input<Tselect>`
    font-family: Nunito, sans-serif;
    font-weight: 500;
    font-size: 16px;
    width: 300px;
    margin: 0px 0px 15px 0px;
    padding: 0px 5px 0px 5px;
    border: none;
    border-bottom: solid 1px rgba(219,219,219,1);
    
    &:focus {
      outline: none;
      border-bottom: solid 2px rgba(0,0,0,1);
    }
    ${(args) => !args.setted && `
      
    `}
    ${(args) => args.setted && `
      color: black;
    `}
  `

  const Botonera = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid rgb(209, 209, 209);
  `
    const Button = styled.button`
      width: 100px;
      border-radius: 5px;
      border: none;
      background-color: transparent;
      font-family: Nunito, sans-serif;
      font-weight: 600;
    `

    interface Tsave { edited: boolean }
    const Save = styled(Button)<Tsave>`
      color: rgb(219,219,219);
      ${(args) => args.edited && `
        color: #ff2a7f;
      `}
    `
    const Cancel = styled(Button)``

    const LoadingContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
    `
    const LoadingSpinner = styled.img`
      height: 25px;
    `