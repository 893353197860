import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setLocation } from '../../../crearNegocio/crearNegocioSlice'

import GmapMarker from './gmapMarker'
import locationPin from '../../../../assets/icons/ui/pin-location.png'
import { useLocation } from 'react-router-dom'

export default function GMapWidget() {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()

  const position = useAppSelector((state: TrootState) => state.crearNegocio.data)
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)

  // Setting map
  useEffect(() => {
    if (ref.current && !map) {

      if (pathname === '/admin') {
        setMap(new window.google.maps.Map(ref.current, {
          center: {
            lat: position.lat ? position.lat : -17.7837026,
            lng: position.lng ? position.lng : -63.1818181
          },
          zoom: 15,
          disableDefaultUI: true,
        }));
      } else  {
        setMap(new window.google.maps.Map(ref.current, {
          center: {
            lat: tarjeta.data?.lat ? tarjeta.data.lat : -17.7837026,
            lng: tarjeta.data?.lng ? tarjeta.data.lng : -63.1818181
          },
          zoom: 15,
          disableDefaultUI: true,
        }));
      }
    }
  }, [ref, map, position, tarjeta?.data]);

  useEffect(() => {
    if (pathname === '/admin') {
      if (map  && position.lat && position.lng) {
        const newCenter = new google.maps.LatLng(position.lat, position.lng)
        map.setCenter(newCenter);
      }
    } else  {
      if (map  && tarjeta.data?.lat && tarjeta?.data.lng) {
        // const newCenter = new google.maps.LatLng(tarjeta.data.lat, tarjeta.data.lng)
        // map.setCenter(newCenter);
      }
    }
  }, [position, tarjeta?.data])

  useEffect(() => {
    function endDrag() {
      const lat = map?.getCenter()?.lat()
      const lng = map?.getCenter()?.lng()
      
      if (lat && lng ) {
        dispatch(setLocation({ lat, lng }))
      }
    }

    if (map) {
      map.addListener ('dragend', endDrag)

      return () => {
        google.maps.event.clearListeners(map, 'dragend');
      }
    }
  }, [map])

  // Mounting Markers
  // useEffect(() => {
  //   if (map) {      
  //     const bounds = new google.maps.LatLngBounds();
  //     for (let i = 0; i < currentTrxs.length; i++) {
  //       let pos = currentTrxs[i].location
  //       if (pos.lat && pos.lon) {
  //         let myLatLng = new google.maps.LatLng(pos.lat, pos.lon)
  //         bounds.extend(myLatLng)
  //       }
  //     }

  //     if (currentTrxs.length === 0) map.setZoom(10)
  //     else map.fitBounds(bounds)      
  //   } 
  // }, [currentTrxs, map])

  // Zoom to Marker
  // useEffect(() => {
  //   if (map && trxs.selected && trxs.selected.location.lat && trxs.selected.location.lon) {
  //     const position = new google.maps.LatLng(trxs.selected.location.lat, trxs.selected.location.lon)
  //     map.setCenter(position);
  //     map.setZoom(14)	
  //   }
  // }, [trxs.selected, map])

  return (
    <>
      <GmapContainer
        ref={ref}
        id="map"
        path={pathname}
      >
        { map && tarjeta.data?.lat && tarjeta.data?.lng && 
          <GmapMarker
            lat={tarjeta.data.lat}
            lng={tarjeta.data.lng}
            map={map}
            idx={0}
            title={tarjeta.data.nombre}
          /> 
        }

      </GmapContainer>
      { pathname === '/admin' &&
        <Pin src={locationPin}/>
      }
    </>
  );
}

interface path { path: string }
const GmapContainer = styled.div<path>`
  width: 100%;
  height: 85vh;
  position: relative;
  ${(args) => args.path === '/' && `
    height: calc(85vh - 60px);
  `}
`
const Pin = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 70px);
  
`