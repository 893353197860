import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import closeIcon from '../../assets/icons/ui/closeGrey.png'
import closeIconSquare from '../../assets/icons/ui/close-square.png'


interface thisProps {
  text: string,
  closeModal: () => void,
  confirmFunction: () => void,
}
export default function ConfirmModal(props: thisProps) {
  const { pathname } = useLocation()

  function hiddeModalFn(event: React.SyntheticEvent) {
    const target = event.target as typeof event.target & {
      id: string
    }

    if(target.id.match(/confirmModalBackground|closeConfirmModalIcon|confirmModalContainer/)) {
      props.closeModal()
    }
  }

  return(
    <ModalBackground
      id='confirmModalBackground'
      style={{zIndex: 998 }}
      onClick={hiddeModalFn}
    >
      <ModalContainer
        style={{zIndex: 999 }}
        id='confirmModalContainer'
      >
        <CloseIconContainer
          id='closeConfirmModalIconContainer'
        >
          <CloseIcon
            src={ pathname.match('/publicacion') ? closeIconSquare : closeIcon}
            id='closeConfirmModalIcon'
          />
        </CloseIconContainer>

        <ConfirmText>
          { props.text }
        </ConfirmText>

        <ConfirmButtons>
          <Confirm
            onClick={() => {
              props.confirmFunction()
            }}
          >
            Aceptar
          </Confirm>

          <Decline
            onClick={() => {
              props.closeModal()
            }}
          >
            Cancelar
          </Decline>
        </ConfirmButtons>
        

      </ModalContainer>
    </ModalBackground>
  )
}

const ModalBackground = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`
const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  margin: auto;
  border-radius: 10px;
  width: 300px;
  height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`
const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  right: 0px;
  top: 5px;
  width: 18px;
`

const CloseIcon = styled.img`
  width: 18px;
`

const ConfirmText = styled.div`
  font-family: Nunito, sans-serif;
  font-weight: 700;
  width: 180px;
`
const ConfirmButtons = styled.div`
  display: flex;
  gap: 20px;
`
  const Confirm = styled.button`
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    background-color: #ffcc00;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
  `
  const Decline = styled.button`
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
  `