import React, { useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import { setContactoNombre, setContactoPhone } from '../crearNegocioSlice'

export default function ContactoData() {
  const dispatch = useAppDispatch()
  const publicar = useAppSelector((state: TrootState) => state.crearNegocio)

  function inputChange(event: React.ChangeEvent<HTMLInputElement>) {

    if (event.target.name === 'negocio-contacto-nombre')
      dispatch(setContactoNombre(event.target.value))

    if (event.target.name === 'negocio-contacto-phono')
      dispatch(setContactoPhone(event.target.value))

  }

  return(
    <ContactoDataContainer>
      <Title> Datos del Contacto </Title>

      <InputContainer>
        <Label>Nombre del propietario o contacto</Label>
        <InputNegocio
          type='text'
          name='negocio-contacto-nombre'
          placeholder="Juan Gonzales"
          value={publicar.data.nombre_contacto ? publicar.data.nombre_contacto : ''}
          defaultValue={publicar.data.nombre_contacto ? publicar.data.nombre_contacto : undefined}
          setted={publicar.data.nombre_contacto ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

      <InputContainer>
        <Label>Telefono del contacto </Label>
        <InputNegocio
          type='text'
          name='negocio-contacto-phono'
          placeholder="70012345"
          value={publicar.data.phone_contacto ? publicar.data.phone_contacto : ''}
          defaultValue={publicar.data.phone_contacto ? publicar.data.phone_contacto : undefined}
          setted={publicar.data.phone_contacto ? true : false}
          onChange={inputChange}
        />
      </InputContainer>

    </ContactoDataContainer>
  )
}

interface Tselect { setted: boolean }
const ContactoDataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`

const Title = styled.div`
  font-family: Nunito;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 400;
`

const InputContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 5px;
`
  const Label = styled.label`
    font-size: 13px;
  `
  const Opcional = styled.span`
    font-size: 10px;
    color: gray;
  `

  const InputNegocio = styled.input<Tselect>`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    width: 300px;
    margin: 5px 0px 0px 0px;
    padding: 5px 5px 5px 15px;
    border: solid 1px gray;
    border-radius: 5px;
    border-color: #00000088;
    &:focus {
      outline: none;
    }
    ${(args) => !args.setted && `
      border-color: #00000066;
    `}
    ${(args) => args.setted && `
      color: black;
      border-color: black;
    `}
  `