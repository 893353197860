import React from 'react'
import styled from 'styled-components'

import LogoImg from '../../assets/images/ui/vcards.png'
import spinner from '../../assets/gifs/loading_1.gif'

export default function Loader() {

  return(
    <Background>
      <Container>
        <Logo src={LogoImg}/>
        <Spinner src={spinner}/>
      </Container>
    </Background>
  )
}

const Background = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1999;
`

const Container = styled.div`
  width: 120px;
  height: 120px;
  background-color: black;
  margin: auto;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Logo = styled.img`
  width: 80%;
  margin: auto;
  margin-left: 15px;
  margin-top: 0px;
`
const Spinner = styled.img`
  width: 40px;
  margin: auto;
  
  position: absolute;
  bottom: 0px;
`