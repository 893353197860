import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setEditingNegocio, backStep, nextStep, cleanEditLocation, cleanSlice} from '../../../../services/editNegocio/editNegocioSlice'

import ModalNoIcon from '../../../modal/modalNoCloseIcon'
import EditInfo from './edit_info'
import EditSocial from './edit_social'
import EditMap from './edit_map'
import EditCatalogos from './edit_catalogo'
import EditPhotos from './edit_photos'


import backIcon from '../../../../assets/icons/crear/back-arrow.png'
import nextIcon from '../../../../assets/icons/crear/next-arrow.png'

export default function EditingTarjeta() {
  const dispatch = useAppDispatch()

  const editing = useAppSelector((state: TrootState) => state.editNegocio)

  return(
    <ModalNoIcon
      customId='editingTarjeta'
      closeModal={() => {
        dispatch(setEditingNegocio(undefined))
        dispatch(cleanSlice())
        dispatch(cleanEditLocation())
      }}
    >
      <EditingContainer>

        <Header>
            
          <BackArrow
            disabled={editing.step === 0 ? true : false}
            onClick={() => {
              dispatch(backStep())
            }}
          >
            <ArrowImg src={backIcon}/>
          </BackArrow>
          

          { editing.step === 0 && <Title> Info </Title> }
          { editing.step === 1 && <Title> Redes Sociales </Title> }
          { editing.step === 2 && <Title> Ubicacion </Title> }
          { editing.step === 3 && <Title> Fotos </Title> }
          { editing.step === 4 && <Title> Catalogo </Title> }
          
          <NextArrow
            disabled={editing.step === editing.steps.length - 1 ? true : false}
            onClick={() => {
              dispatch(nextStep() )
            }}
          >
            <ArrowImg src={nextIcon}/>
          </NextArrow>

        </Header>

        <Navigator>
          { Array.apply(null, Array(editing.steps.length)).map((dot, idx) => (
            <Dot 
              key={`dot-nav-${idx}`}
              isActive={idx === editing.step}
            />
          ))}
        </Navigator>

        { editing.step === 0 && <EditInfo/> }
        { editing.step === 1 && <EditSocial/> }
        { editing.step === 2 && <EditMap/> }
        { editing.step === 3 && <EditPhotos/> }
        { editing.step === 4 && <EditCatalogos/> }

      </EditingContainer>

    </ModalNoIcon>
  )
}

const EditingContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: white;
  width: 340px;
  padding: 10px 20px;
  height: 80vh;
  border-radius: 5px;
`

const Title = styled.div`
  font-family: Roboto;
  width: 100%;
  font-size: 18px;
  text-align: center;
  
  margin-bottom: 0px;
  font-weight: 500;
`

const Navigator = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin: 10px 0px 25px 0px;
`
  interface isActive { isActive: boolean }
  const Dot = styled.div<isActive>`
    background-color: #201e1f55;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    ${(args) => args.isActive && `
      background-color: #201e1f;
    `}
  `

const Header = styled.div`
  display: flex;
  max-width: 300px;
  min-width: 300px;
  margin: auto;
  align-items: center;
  margin-top: 5px;
`
  interface navButtons { disabled: boolean }
  const BackArrow = styled.div<navButtons>`
    ${(args) => args.disabled && `
      opacity: 0.2;
    `}
  `
  const NextArrow = styled.div<navButtons>`
    ${(args) => args.disabled && `
      opacity: 0.2;
    `}
  `

  const ArrowImg = styled.img`
    width: 35px;
  `
