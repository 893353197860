import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../../../app/store'
import { TtarjetaStates } from '../../tarjeta'
import useConfig from '../../../../hooks/useConfig'
import { likeNegocio, dislikeNegocio } from '../../../../services/user_ops/user_ops'
import { logEvent } from '../../../../services/logger/api'
import { useLocation, useSearchParams } from 'react-router-dom'

import map from '../../../../assets/icons/tarjeta/map.png'
import share from '../../../../assets/icons/tarjeta/share.png'
import heart from '../../../../assets/icons/tarjeta/heart.png'
import heartFilled from '../../../../assets/icons/tarjeta/heart-filled.png'
import back from '../../../../assets/icons/tarjeta/back.png'
import gmapsIcon from '../../../../assets/icons/tarjeta/google_maps.png'
import loading from '../../../../assets/gifs/infinity-loader.gif'


interface TtoShare { title: string, files: File[], url: string }
interface props { navigate: (to: TtarjetaStates) => void, tarjetaState: TtarjetaStates }
export default function TarjetaNavigator(props: props) {
  const { app } = useConfig()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loadingShare, setLoadingShare] = useState(false)
  const [toShare, setToShare] = useState<TtoShare|undefined>(undefined)
  //const [ like, setLike ] = useState<boolean>(props.liked? props.liked : false )
  const [ like, setLike ] = useState<boolean>(false)
  const timestamp = Date.now();

  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)
  const user = useAppSelector((state: TrootState) => state.user)

  useEffect(() => {
    // setLoadingShare(true)
    // if (tarjeta.data) {
    //   fetch(`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${tarjeta.data?.negocio_id}/thumb_250x250.jpg?date=${timestamp}`)
    //   .then((res) => res.blob())
    //   .then((blob) => {
    //     fetch(`https://s3.sa-east-1.amazonaws.com/mibarrio.static.files/template/marco/marco.png`)
    //     .then((marcoRes) => marcoRes.blob())
    //     .then( async (marcoBlob) => {
    //       setLoadingShare(false)
    //     })
    //   });
    // }

    if (tarjeta.data) {
      prepareShare()
    }

  }, [tarjeta.data])

  
  function logThis(message: string) {
    let path = pathname
    if (searchParams.get('negocio')) path = path + '?negocio=' + searchParams.get('negocio')
    
    logEvent({ message, path })
  }

  function favPublicacion() {
    if (user.data) {
      if (like) {
        dislikeNegocio((tarjeta.data?.negocio_id as string))
        setLike(false)
      }
      else {
        likeNegocio((tarjeta.data?.negocio_id as string))
        setLike(true)
      }
    }
    // else {
    //   displayModal()
    // }
  }

  async function prepareShare() {
    if (loadingShare) return

    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    setLoadingShare(true)
    fetch(`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${tarjeta.data?.negocio_id}/thumb_250x250.jpg`, {
      headers: myHeaders,
    })
    .then((res) => res.blob())
    .then( async (blob) => {
      

      fetch(`https://s3.sa-east-1.amazonaws.com/mibarrio.static.files/template/marco/marco.png`, {
        headers: myHeaders,
      })
      .then((marcoRes) => marcoRes.blob())
      .then( async (marcoBlob) => {

        

        let canvas = document.createElement('canvas');
        canvas.width = 500;
        canvas.height = 500;
        let context = canvas.getContext('2d');

        const negocioImg = document.createElement("img");

        

        negocioImg.onload = () => {
          const marcoImg = document.createElement("img");


          marcoImg.onload = () => {


            if (context) {


              context.drawImage(negocioImg, 0, 0, 500, 500 );
              context.drawImage(marcoImg, 0, 0, 500, 500 );

              if (tarjeta.data?.nombre) {

                
                context.font = 'bold 20px sans-serif';
                context.fillStyle = "#000000";
                context.fillText(tarjeta.data?.nombre, 10, 25);
              }

              canvas.toBlob(async (imageWithMarcoBlob) => {
                

                if (imageWithMarcoBlob) {
                  

                  const filesArray = [
                    new File(
                      [imageWithMarcoBlob],
                      `vcards-${tarjeta.data?.negocio_id}.jpg`,
                      {
                        type: "image/jpeg",
                        lastModified: new Date().getTime()
                      }
                    )
                  ];


            
                  setToShare({
                    title: `${tarjeta.data?.nombre} - VCards Bussines`,
                    // text: `Dale una mirada a mi tarjeta de Contacto!\n\n${tarjeta.data?.nombre}\n${tarjeta.data?.tipo}\n`,
                    // text: `Dale una mirada a mi tarjeta de Contacto!`,
                    files: filesArray,
                    url: `https://vcardsbusiness.net/negocios/${tarjeta.data?.negocio_id}`
                  })

                  
              
                  // Disabling loading
                  setLoadingShare(false)
                }
              })
            }
          }
          marcoImg.src = URL.createObjectURL(marcoBlob);
        }
        negocioImg.src = URL.createObjectURL(blob);  
      })
      .catch((err) => {
        console.log('share marcoImg fetch error: ' + err )
        logThis('share marcoImg fetch error: ' + err )
      })
    })
    .catch((err) => {
      console.log('share mainImg fetch error: ' + err )
      logThis('share mainImg fetch error: ' + err )
    })
  }

  return(
    <Background
      tarjetaState={props.tarjetaState}
    >
      <Container
        tarjetaState={props.tarjetaState}
      >

        { props.tarjetaState === 'tarjeta' &&
          <>
            <LeftSide>
              <LikeFrame>
                <LikeIcon src={ like ? heartFilled : heart}
                  onClick={() => {
                    favPublicacion()
                  }}
                />
              </LikeFrame>

              <LikeFrame>
                <LikeIcon src={ loadingShare ? loading : share }
                  onClick={() => {
                    if (toShare) navigator.share(toShare)
                  }}
                />

              </LikeFrame>
            </LeftSide>
          
            <VerUbicacion
              onClick={() => props.navigate('mapa') }
            >
              <UbicacionText> Ubicación </UbicacionText>
              <IconFrame>
                <UbicacionIcon src={map} />
              </IconFrame>
            </VerUbicacion>
          </>
        }

        { props.tarjetaState === 'mapa' &&
          <>
            <LeftSide>
              { tarjeta.data && tarjeta.data.lat && tarjeta.data.lng &&
                <OpenInGmap
                  href={
                    tarjeta.data.google_placeid ?
                    `https://www.google.com/maps/search/?api=1&query=null&query_place_id=${tarjeta.data.google_placeid}`
                    :
                    `https://www.google.com/maps/search/?api=1&query=${tarjeta.data.lat}%2C${tarjeta.data.lng}`
                  }
                  target='_blank'
                >

                  <GmapIcon src={gmapsIcon}/>
                  <GmapText>
                    Abrir en Google Maps
                  </GmapText>
                  
                </OpenInGmap>
              }
            </LeftSide>

            <Volver
              onClick={() => props.navigate('tarjeta') }
            >
              <VolverText>
                Volver
              </VolverText>
              <VolverIcon src={back}/>
            </Volver>
          </>
        }

      </Container>
    </Background>
  )
}

const Background = styled.div<Tcontainer>`
  display: flex;
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 5px, #ffffff 5px);
  height: 70px;
  width: 90vw;
`

interface Tcontainer { tarjetaState: 'mapa' | 'tarjeta' | 'estados' }
const Container = styled.div<Tcontainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;

  width: 100%;
  height: 60px;
  padding: 0px 10vw;
  padding: 0px 15px;
`

const LeftSide = styled.div`
  display: flex;
  gap: 10px;
`

const VerUbicacion = styled.button`
  display: flex;  
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 15px;
  width: 115px;
  height: 40px;
  border: none;
  background-color: white;
  border-radius: 10px;
`
  const UbicacionText = styled.div`
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #2d3b41;
  `
  const IconFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    background-color: transparent;
  `
  const UbicacionIcon = styled.img`
    height: 18px;
  `

const LikeFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 36px;
  height: 36px;
  border-radius: 10px;
`
  const LikeIcon = styled.img`
    height: 30px;
  `

const Volver = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
  const VolverText = styled.div`
    font-family: Nunito, sans-serif;
    font-size: 14px;
    font-weight: 700; 
  `
  const VolverIcon = styled.img`
    height: 25px;
  `

const OpenInGmap = styled.a`
  display: flex;
  align-items: center;
  background-color: #88888822;
  padding: 5px 10px 5px 5px;
  border-radius: 5px;
  border: none;
  gap: 10px;
  text-decoration: none;
`
  const GmapText = styled.span`
    font-size: 12px;
    font-family: Nunito, sans-serif;
    font-weight: 700;
    color: black;
  `
  const GmapIcon = styled.img`
    height: 25px;
  `