import { configureStore, Action } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { ThunkAction } from "@reduxjs/toolkit"

import user from "../services/auth/userSlice"
import appUi from "./AppUiSilce"
import tarjeta from "../services/tarjeta/tarjetaSlice"
import crearNegocio from "../components/crearNegocio/crearNegocioSlice"
import admin from "../services/admin/adminSlice"
import popUp from "../components/popUp/popUpSlice"
import buscador from "../components/buscador/buscadorSlice"
import position from "../services/position/positionSlice"
import corporacion from "../services/corporacion/corporacionSlice"
import editNegocio from "../services/editNegocio/editNegocioSlice"

import { negociosApi } from "../services/negocios/queryApi"
import { negocioApi } from "../services/negocio/queryApi"

import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"

const store = configureStore({
  reducer: {
    user,
    appUi,
    tarjeta,
    crearNegocio,
    admin,
    popUp,
    buscador,
    position,
    corporacion,
    editNegocio,
    [negociosApi.reducerPath]: negociosApi.reducer,
    [negocioApi.reducerPath]: negocioApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(negociosApi.middleware)
    .concat(negocioApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export type TrootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, TrootState, null, Action<string>>

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<TrootState> = useSelector

setupListeners(store.dispatch)

export default store