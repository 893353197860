import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'

import { TnegocioItem } from '../../services/negocio/negociosApiTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import { useAppDispatch } from '../../app/store'
import { setNegocioId } from '../../services/tarjeta/tarjetaSlice'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import loading from '../../assets/icons/ui/eclipse.svg'

export default function NegocioRow(negocio: TnegocioItem) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [, setSearchParam] = useSearchParams()
  const { app } = useConfig()

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  function distanceFormat(distance: number) {
    if (distance < 1 ) 
    return Math.round(distance * 1000) + ' mt'
    else
    return Math.round(distance * 10) / 10 + ' km'
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading}
    />
  ), [])

  return(
    // <Link
    //   to={`/negocios/${negocio.negocio_id}`}
    //   state={{ background: location }}
    //   style={{ textDecoration: 'none' }}
    // >
      <NegocioRowContainer
        onClick={() => {
          setSearchParam({ negocio: negocio.negocio_id })
          dispatch(setNegocioId(negocio.negocio_id))
        }}
      >
        <Left>
          <ThumbContainter>
            <Thumb>
            <MyImage
              className='imageFit'
              alt="member image"
              src={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.negocio_id}/thumb_150x150.jpg`}
              width={'100%'}
              height={'100%'}
            />
            </Thumb>
          </ThumbContainter>
        </Left>

        <Center>
          <Row1>
            <NegocioTitle> { negocio.nombre } </NegocioTitle>
            
          </Row1>
          <Row2>
            <NegocitoTipo> { negocio.tipo } </NegocitoTipo>
          </Row2>
        </Center>

        <Right>
          { negocio.distance && <Distance> { distanceFormat(negocio.distance) } </Distance> }
          <div></div>
        </Right>

      </NegocioRowContainer>
    // </Link>
  )
}

const NegocioRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 20px;
  min-width: 90%;
  
`
const Left = styled.div``
  const Thumb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #ffffffcc;
  `
    const ThumbContainter = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 78px;
      border-radius: 5px;
      //background-color: black;
      background: linear-gradient(180deg, rgba(180,180,180,0) 0%, rgba(180,180,180,1) 100%);
      //background: linear-gradient(135deg, rgba(180,180,180,0) 70%, rgba(100,100,100,1) 100%);
    `
  

const Center = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 4;
  padding: 0px 10px;
`

const Right = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex-grow: 8;
  padding: 0px 0px 0px 0px;
  min-width: 60px;
  position: relative;
`

const Row = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
  const Row1 = styled(Row)`
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    width: 100%;
  `
  const Row2 = styled(Row)`
    justify-content: flex-start;
    height: 40px;
  `
  const NegocioTitle = styled.p`
    font-family: Roboto;
    font-weight: 500;
    color: white;
    color: black;
    font-size: 13px;
    margin: 0px;
  `
  const NegocitoTipo = styled.p`
    font-family: Roboto;
    color: gray;
    font-size: 13px;
    margin: 0px;
    max-height: 20px;
    overflow: hidden;
  `
  const Distance = styled.div`
    font-family: Roboto, sans-serif;
    color: #50b2c0;
    color: #ffffff;
    color: black;
    
    background: linear-gradient(180deg, #868f96   0%, #596164 100%);
    background: #434343;
    background: transparent;
    border: solid 1px #434343;
    border-radius: 2px;
    width: 50px;
    align-self: flex-end;
    position: absolute;
    top: 5px;

    
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px; // <= whatch out
  `