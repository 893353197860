import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TpositionState } from "./positionTypes";

const initialState: TpositionState = {
  lat: undefined,
  lng: undefined
}

const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    setPosition(state, action: PayloadAction<{ lat: number, lng: number }>) {
      state.lat = action.payload.lat
      state.lng = action.payload.lng
    }
  }
})

export const {
  setPosition,
} = positionSlice.actions

export default positionSlice.reducer