/**
 * Configuration
 */
//  import manifest from "../../public/manifest.json";
import manifest from "./manifest.json";

/** Whether we're running on a local desktop or on AWS Lambda */
const isLocal = process.env.IS_LOCAL || process.env.IS_OFFLINE;
const isProduction = process.env.VINERESERVE_STAGE && process.env.VINERESERVE_STAGE === 'prod' ? true : false;

/**
 * Configuration Options
 *
 * IMPORTANT:
 * The config is injected into the client (browser) and accessible through the {@link useConfig}
 * hook. However, due to this behavior, it is important NOT to expose any sensitive information
 * such as passwords or tokens through the config.
 */

const config = {
  /** Application Config */
  app: {
    /** Name of the app is loaded from the `manifest.json` */
    TITLE: manifest.short_name,
    /** Theme is also loaded from the `manifest.json` */
    THEME_COLOR: manifest.theme_color,
    /** URL to our public API Gateway endpoint */
    URL: isLocal ? `http://localhost:3000` : String(process.env.APIGATEWAY_URL),
    /** Where the bundled distribution files (`main.js`, `main.css`) are hosted */
    DIST_URL: isLocal ? "http://localhost:8080" : String(process.env.APP_DIST_URL),
    /** Where the contents of the `public` folder are hosted (might be the same as `config.app.DIST_URL`) */
    PUBLIC_URL: isLocal ? "http://localhost:8080" : String(process.env.APP_PUBLIC_URL),
    BACKEND_URL: process.env.BACKEND_URL? process.env.BACKEND_URL : 'https://vcardsbusiness.net/',
    COOKIE_USER: "redbol_user",
    COOKIE_SIGNUP: "redbol_signup",

    DEFAULT_AVATAR: 'https://s3.amazonaws.com/vinereserveclub.static.files/users/general/user-default-profile.png',

    STAGE: process.env.SERVERLESS_STAGE ? process.env.SERVERLESS_STAGE : 'dev',
    PROJECT: process.env.SERVERLESS_PROJECT ? process.env.SERVERLESS_PROJECT : 'vinereserve-ssi',
    COGNITO_IDENTITY_POOL: process.env.VINERESERVE_COGNITO_IDENTITYPOOLID ? process.env.VINERESERVE_COGNITO_IDENTITYPOOLID : 'us-east-1:318f1ae7-b262-4bfe-850b-589bcaa32c88',
    COGNITO_POOL: process.env.SERVERLESS_USERPOOL_ID ? process.env.SERVERLESS_USERPOOL_ID : 'us-east-1_Erg0ahg1L',

    STATIC_BUCKET: process.env.VINERESERVE_S3_STATIC ? process.env.VINERESERVE_S3_STATIC :'mibarrio.static.files',
  },
};

export type Config = typeof config;
export default config;