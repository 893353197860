import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useGetLastRegisteredQuery } from '../../../services/negocios/queryApi'
import NegocioRow from '../../../components/negocioRow/negocioRow'
import { Tnegocios } from '../../../services/negocios/apiTypes'
import { TnegocioItem } from '../../../services/negocio/negociosApiTypes'

export default function LastRegistered() {

  const { data: lastRegistered, error, isSuccess, isFetching, isLoading, refetch } = useGetLastRegisteredQuery({} , {
    pollingInterval: 0
  })

  useEffect(() => {
    console.log(lastRegistered)
  }, [lastRegistered])

  return(
    <Container>
      <Title>
        Negocios Registrados
      </Title>
      <Ruler/>
      {
        lastRegistered && (lastRegistered as Tnegocios).items.map((negocio: TnegocioItem) => (
          <NegocioRow
            { ...negocio }
            key={negocio.negocio_id}
          />
        ))
      }
      <div style={{ minHeight: '75px', minWidth: '100%'}} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  
  width: 100%;
`

const Title = styled.div`
  font-family: nunito, sans-serif;
  font-weight: 600;
  margin: 30px 0px 0px 20px; 
`
const Ruler = styled.div`
  margin-left: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid gray; 
  width: 200px;
`