import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'
import { GoogleLogin } from '@react-oauth/google'

import { initSession, googleLogin } from "../../../services/auth/userSlice"
import { TsignInStep } from './SignIn'

import loadingGif from '../../../assets/gifs/loading.gif'
import Logo from '../../../assets/images/ui/vcard-logo.png'

interface Tlogin { changeStep: (step: TsignInStep) => void }
export default function Login(props: Tlogin) {
  const dispatch = useAppDispatch()
  const { changeStep } = props
  
  const [ step, setStep ] = useState<string>('login')
  const [ errorMsg, setErrorMsg ] = useState<string | null>(null)
  const [ enableLogin, setEnableLogin ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const emailInput = useRef<HTMLInputElement>(null)
  const passInput = useRef<HTMLInputElement>(null)

  const userState = useAppSelector((state: TrootState) => state.user)

  useEffect(() => {
    if (userState.status === 'error' && userState.errorMsg) {
      setErrorMsg(userState.errorMsg)
    } else {
      setErrorMsg(null)
    }
  }, [userState])

  useLayoutEffect(() => {
    if (step === 'login') {
      if (emailInput.current && passInput.current) {
        setErrorMsg('');
        setEnableLogin(false)
        emailInput.current.value = '';
        passInput.current.value = '';
      }
    }
  }, [step])

  function inputChange() {
    if (emailInput.current && passInput.current) {
      if (emailInput.current.value !== '' && passInput.current.value !== '')  {
        setEnableLogin(true);
      } else {
        setEnableLogin(false);
      }
    }
  }

  function initLogin(event: React.SyntheticEvent) {
    try {
    event.preventDefault()

    setLoading(true)
    if (emailInput.current && passInput.current) {
      dispatch(initSession({
        username: emailInput.current.value,
        password: passInput.current.value
      }))
      .then((result: any) => {
        console.log('initLogin: ', result)
        setLoading(false)

        if (result.meta.requestStatus === 'fulfilled') {

        }
      })
      .catch((err: any) => {
        console.log('err: ',err)
        setLoading(false)
      })
    }
    } catch (e) {
      console.log('error google: ', e)
    }
  }

  return(
    <FormContainer>

      <SubTitle>
        INICIA SESIÓN
      </SubTitle>

      <ThumbContainer
        className='ThumbContainer'
      >
        <ThumbImage src={Logo}/>
      </ThumbContainer>

      <Form onSubmit={initLogin}>

        <Input
          ref={emailInput}
          type="text"
          error={errorMsg ? true : false}
          placeholder="usuario"
          name="email"
          onChange={() => {inputChange()}}
        />

        <Input
          ref={passInput}
          type="password"
          error={errorMsg ? true : false}
          placeholder="contraseña"
          name="password"
          onChange={() => {inputChange()}}
        />

        { errorMsg &&
          <ErrorMessage
            display={errorMsg ? true : false}
          >
            { errorMsg } 
          </ErrorMessage>
        }

        <ForgotPassword onClick={ () => { changeStep('resetPassword') } }>
          Olvidaste la Contraseña?
        </ForgotPassword>

        <LoginButton
          type="submit"
          ready={enableLogin}
          disabled={!enableLogin || loading ? true : false}
        >
          { loading ? '' : 'Iniciar Sesión' }
          <LoadingGif isLoading={loading} src={loadingGif}/>
        </LoginButton>

      </Form>

      <OtherLogin> O iniciá sessión con google </OtherLogin>

      <GoogleLogin
        
        locale='es_SP'
        text='continue_with'
        onSuccess={credentialResponse => {
          console.log(credentialResponse);
          if (credentialResponse.credential) {
            const credential = credentialResponse.credential
            const segment = credential.split('.')
            const parsed = JSON.parse(atob(segment[1]))

            dispatch(googleLogin({ google_token: credential  }))
            .catch((error: any) => {
              console.log(error)
              // dispatch(setLogEvent({ type: 'error', message: error.response.data.message }))
            })
          }
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        useOneTap
      />

      
      <SignUp>
        No tienes una cuenta? 

        <HighLighted
          onClick={() => {
            changeStep('signup')            
          }}
        >
          Registrate
        </HighLighted>

      </SignUp>

    </FormContainer>
  )
}

const ThumbContainer = styled.div`
  background-color: #ffffffcc;
  width: 131px;
  height: 131px;
  border-radius: 100px;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
`
  const ThumbImage = styled.img`
    width: 120px;
    height: 120px;
    object-fit: cover;
  `

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 300px;
`
const SubTitle = styled.p`
  font-size:  25px;
  font-weight: 800;
  text-align: left;
  max-width: 350px;
  margin: 10px auto;
  color: #201E1F;
  font-family: Roboto;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`
  interface Tinput { error: boolean }
  const Input = styled.input<Tinput>`
    width: 90%;
    border: none;
    border-radius: 5px;
    border: 1px solid #f7efe7;
    font-size: 13px;
    line-height: 30px;
    margin: 15px auto 0px auto;
    padding: 5px 5px 5px 15px;
    ${(args) => args.error && `
      border: 1px solid #e73c37;
    `};
    &:focus {
      outline: 1px solid black;
    }
  `

  interface TerrorMessage { display: boolean }
  const ErrorMessage = styled.p<TerrorMessage>`
    align-self: flex-end;
    display: none;
    font-weight: 500;
    color: #e73c37;
    font-size: 12px;
    margin-top: 5px;
    text-align: right;
    ${(args) => args.display && `
      display: initial;
    `}
  `

  const ForgotPassword = styled.p`
    align-self: flex-end;
    font-size: 12px;
    font-weight: 600;
    margin: 10px 0px 30px 0px;
    cursor: pointer;
    text-align: right;
    color: white;
    font-family: Nunito;
    color: #312C38;
  `
  interface TloginButton { ready: boolean }
  const LoginButton = styled.button<TloginButton>`
    width: 100%;
    background-color: #50b2c055;
    border-radius: 5px;
    font-size: Montserrat;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0px 10px 0px;
    color: white;
    border: none;
    text-decoration: none;
    ${(args) => args.ready && `
      background-color: #50b2c0;
    `}
  `
    interface TloadingGif { isLoading: boolean }
    const LoadingGif = styled.img<TloadingGif>`
      width: 23px;
      display: none;
      ${(args) => args.isLoading && `
        display: initial;
      `}
    `

  const OtherLogin = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin: 10px 0px;
  `

const SignUp = styled.p`
  align-self: flex-end;
  font-size: 14px;
  font-weight: 00;
  margin-top: 20px;
  cursor: pointer;
  text-align: right;
  text-decoration: none !important;
  color: #312C38;
  font-family: Roboto;
`
const HighLighted = styled.span`
  color: #50B2C0;
  font-weight: 600;
  margin-left: 5px;
`



