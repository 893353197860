import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setAlreadyEdited, setUpdating, cleanSlice, setEditLocation } from '../../../../services/editNegocio/editNegocioSlice'
import { updateNegocioInfo } from '../../../../services/editNegocio/api'


import GmapWrapper from '../../../gmaps/googleMapsWrapper'

import loading from '../../../../assets/gifs/infinity-loader.gif'



export default function EditMap() {
  const dispatch = useAppDispatch()
  const [ edited, setEdited ] = useState<boolean>(false)

  const editing = useAppSelector((state: TrootState) => state.editNegocio)
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)

  useEffect(() => {
    if (tarjeta.data) {
      dispatch(setEditLocation({
        lat: tarjeta.data.lat,
        lng: tarjeta.data.lng,
        googlePlaceId: undefined,
        text: undefined,
      }))
    }
  }, [tarjeta.data])

  useEffect(() => {
    const { lat, lng, googlePlaceId, text } = editing.location
    
    if (googlePlaceId && googlePlaceId !== tarjeta.data?.google_placeid) {
      setEdited(true)
    } else if (lat !== tarjeta.data?.lat) {
      setEdited(true)
    } else if (lng !== tarjeta.data?.lng) {
      setEdited(true)
    } else {
      setEdited(false)
    }
  
  }, [editing])
  
  function saveDataChanges() {
    if (editing.editing) {
      dispatch(setUpdating('updating'))
      updateNegocioInfo({
        negocio_id: editing.editing,
        lat: editing.location.lat,
        lng: editing.location.lng,
        google_placeid: editing.location.googlePlaceId ?? undefined,
      })
      .then((resp) => {
        dispatch(setAlreadyEdited(0))
        dispatch(setUpdating('updated'))      
      })
      .catch((err) => {
        console.log(err)
        dispatch(setUpdating('initial'))
      })
    }
  }

  return(
    <Container>

      <MapContainer>
        <GmapWrapper/>

          <PlaceContainer>
            { editing.location.text ?
              <PlaceText>
                { editing.location.text }
              </PlaceText>
              :
              <CurrentPosition>
                <Pos>
                  <b> Long: </b>
                  { editing.location.lat }
                </Pos>

                <Pos>
                  <b> Lat: </b>
                  { editing.location.lng }
                </Pos>
              </CurrentPosition>      
            }

        


            { editing.location.googlePlaceId &&
              <LinkUrl
                href={`https://www.google.com/maps/search/?api=1&query=${editing.location.lat}%2C${editing.location.lng}&query_place_id=${editing.location.googlePlaceId}`}
                target='_blank'
              >
                { `https://www.google.com/maps/search/?api=1&query=${editing.location.lat}%2C${editing.location.lng}&query_place_id=${editing.location.googlePlaceId}` }
              </LinkUrl>
            }
          </PlaceContainer>
        
      </MapContainer>

      <Botonera>
        { editing.updating === 'updating' ?
            <LoadingContainer>
              <LoadingSpinner src={loading}/>
            </LoadingContainer>
          :
            <Save
              edited={edited}
              disabled={!edited}
              onClick={() => {
                saveDataChanges()
              }}
            >
              Guardar
            </Save>
        }

        <Cancel
          onClick={() => {
            dispatch(cleanSlice())
          }}
        >
          Cerrar
        </Cancel>

      </Botonera>

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-grow: 2;
`


const Botonera = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid rgb(209, 209, 209);
`
  const Button = styled.button`
    width: 100px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    font-family: Nunito, sans-serif;
    font-weight: 600;
  `

  interface Tsave { edited: boolean }
  const Save = styled(Button)<Tsave>`
    color: rgb(219,219,219);
    ${(args) => args.edited && `
      color: #ff2a7f;
    `}
  `
  const Cancel = styled(Button)``

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  `
  const LoadingSpinner = styled.img`
    height: 25px;
  `


const MapContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const PlaceContainer = styled.div`
  margin-top: 10px;
`
  const PlaceText = styled.div`
    font-family: Nunito, sans-serif;
    font-weight: 700;
    font-size: 14px;
  `
  const LinkUrl = styled.a`
    font-family: Roboto, sans-serif;
    font-size: 10px;
    word-break: break-all;
  `

const CurrentPosition = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
`
  const Pos = styled.div`
    font-size: 12px;
    margin: 0px;
  `