import axios from 'axios'
import { app } from '../servicesConfig'
import { AxiosResponse, AxiosError } from 'axios'

const BACKEND_URL = app.BACKEND_URL


interface TLogEventParams {message: string, path: string} 
export function logEvent(params: TLogEventParams) {
  const axiosOptions = {
    method: 'POST',  
    url: `${BACKEND_URL}/logger`,
    data: JSON.stringify({
      ...params
    }),
  };

  return new Promise<AxiosResponse<{ result: string }>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}
