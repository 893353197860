import { checkPrimeSync } from 'crypto';
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface TmarkerProps { lat: number, lng: number, map: google.maps.Map | undefined, title: string , idx: number}

export default function GmapMarker(props: TmarkerProps) {
  const { lat, lng, map, title, idx } = props;
  const [marker, setMarker] = React.useState<google.maps.Marker|null>(null)
  let mounted: boolean = false 
  

  useEffect(() => {
    //console.log('mounting')
    if (map && !marker && !mounted) { 
      console.log(`mounting1 - ${idx}`)
      mounted = true;
      setMarker(new google.maps.Marker({ 
        position: { lat, lng },
        map,
        title,
      }));
      // marker = new google.maps.Marker({ 
      //   position: { lat, lng },
      //   map,
      //   title: "Hello World2222!",
      // })
    }

    // if (marker) {
    //   console.log('marker')
    // } else {
    //   console.log('No marker')
    // }

    // remove marker from map on unmount
    return () => {
      if (marker !== null) {
        // console.log('unmmount2')
        marker.setMap(null);
        //setMarker(null)
      }
    };
  }, [marker, map]);



  return null
}