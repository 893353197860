import React, { useState, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { setPhoto, setDeletePhoto } from '../crearNegocioSlice'
import { TrootState, useAppDispatch, useAppSelector } from '../../../app/store'

import upload from '../../../assets/icons/crear/upload.png'
import deleteIcon from '../../../assets/icons/ui/delete.png'
import pdf from '../../../assets/icons/tarjeta/pdf-rojo.png'
import emptyPdf from '../../../assets/icons/tarjeta/pdf-gray.png'


interface Tfile { dataUrl: string, loaded: boolean }
interface Tprops { 
  fotos: Blob[] , loadFotos: Dispatch<SetStateAction<Blob[]>>,
  catalogo: Blob|undefined , loadCatologo: Dispatch<SetStateAction<Blob|undefined>>,
}
export default function ImageData(props: Tprops) {
  const dispatch = useAppDispatch()
  const [ photos, setPhotos ] = useState<Tfile[]>([{ dataUrl: upload, loaded: false }])

  
  
  async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as typeof event.target & {
      files: FileList;
    };

    const filesRaw: File[] = []

    for (let file in target.files ) {
      if (typeof target.files[file] === 'object') {
        filesRaw.push(target.files[file])
      }
    }

    const files = filesRaw.map(file => {
      const reader = new FileReader()

      return new Promise<Blob>(resolve => {
        reader.onload = (e) => {
          if (e.target?.result) {
            let binary = window.atob(e.target.result.toString().split(',')[1])
            let array = []
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i))
            }
    
            let blobData = new Blob([new Uint8Array(array)], {type: '.jpeg'})
            // resolve({ dataUrl: e.target.result.toString(),  loaded: true })
            resolve(blobData)
          }
        }

        reader.readAsDataURL(file)
      })
    })
    
    const resultFiles = await Promise.all(files)
    props.loadFotos( [...props.fotos, ...resultFiles])
  }

  async function pdfChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as typeof event.target & {
      files: FileList;
    };

    const filesRaw: File[] = []

    for (let file in target.files ) {
      if (typeof target.files[file] === 'object') {
        filesRaw.push(target.files[file])
      }
    }

    const files = filesRaw.map(file => {
      const reader = new FileReader()

      return new Promise<Blob>(resolve => {
        reader.onload = (e) => {
          if (e.target?.result) {
            let binary = window.atob(e.target.result.toString().split(',')[1])
            let array = []
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i))
            }
    
            let blobData = new Blob([new Uint8Array(array)], {type: '.jpeg'})
            // resolve({ dataUrl: e.target.result.toString(),  loaded: true })
            resolve(blobData)
          }
        }

        reader.readAsDataURL(file)
      })
    })
    
    const resultFiles = await Promise.all(files)
    props.loadCatologo(resultFiles[0])
  }

  return(
    <ImageDataContainer>

      <Title> Subir Imagenes </Title>

      <Photos>
        { props.fotos.map((foto, idx) => (
          <PhotoContainer
            key={`foto-upload-${idx} `}
          >
            <DeleteContainer
              // hideThis={ idx === publicar.photos.length - 1  }
              hideThis={ false }
              onClick={() => {
                const splicedFotos = [...props.fotos]
                splicedFotos.splice(idx, 1)
                props.loadFotos(splicedFotos)
              }}
            >
              <DeleteIcon src={deleteIcon}/>
            </DeleteContainer>

            <PhotoIcon 
              src={ URL.createObjectURL(foto)}
            />

            <PhotoTitle> 
              { `Foto ${idx + 1}`} 
            </PhotoTitle>

          </PhotoContainer>
        ))}

        <PhotoContainer>
          <PhotoIcon 
            src={ upload }
            onClick={() => {
              document.getElementById(`label-crearnegocio-uploadfoto`)?.click()
            }}
          />

          <PhotoTitle> 
            { props.fotos.length === 0 ? `Foto principal` :
              'Foto Adicional (opcional)'
            } 
          </PhotoTitle>

          <FileUpload
            htmlFor="file-crearnegocio-uploadfoto"
            id="label-crearnegocio-uploadfoto"
          />

          <HiddenInput 
            id="file-crearnegocio-uploadfoto"
            type="file"
            name={`foto_${props.fotos.length}`}
            multiple={false}
            onChange={onFileChange}
            accept="image/jpeg"
          />
        </PhotoContainer>

      </Photos>


      <Title> Subir Catalogo (Opcional) </Title>

      <Photos>
        { props.catalogo &&
          <PhotoContainer>
            <DeleteContainer
              hideThis={ false }
              onClick={() => {
                props.loadCatologo(undefined)
              }}
            >
              <DeleteIcon src={deleteIcon}/>
            </DeleteContainer>

            <PhotoIcon src={ pdf }/>

            <PhotoTitle> 
              { `Catalogo Seleccianado` } 
            </PhotoTitle>

          </PhotoContainer>
        }

        { !props.catalogo && 
        <PhotoContainer>
          <PhotoIcon 
            src={ emptyPdf }
            onClick={() => {
              document.getElementById(`label-crearnegocio-uploadcatalogo`)?.click()
            }}
          />

          <PhotoTitle> 
            Subir Catalogo
          </PhotoTitle>

          <FileUpload
            htmlFor="file-crearnegocio-uploadcatalogo"
            id="label-crearnegocio-uploadcatalogo"
          />

          <HiddenInput 
            id="file-crearnegocio-uploadcatalogo"
            type="file"
            name="catalogo.pdf"
            multiple={false}
            onChange={pdfChange}
            accept="application/pdf"
          />
        </PhotoContainer>
        }

      </Photos>

    </ImageDataContainer>
  )
}

const ImageDataContainer = styled.div``

const Title = styled.div`
  font-family: Nunito;
  width: 80%;
  font-size: 14px;
  text-align: left;
  margin: 5px auto 10px auto;
  font-weight: 700;
`

const PhotoContainer = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 120px;
  gap: 10px;
`

const PhotoIcon = styled.img`
  width: 60px;
`

const PhotoTitle = styled.p`
  font-family: Montserrat;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
  width: 90px;
`

const FileUpload = styled.label`

  font-family: Barlow;
  font-weight: 600;
  background: #61dafb;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  display: inline-block;
  padding: 5px 15px;
  cursor: pointer;
  width: 240px;
  margin-top: 15px;
  display: none;
`

const HiddenInput = styled.input`
  display: none;
`

const Photos = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`


interface hideThis { hideThis: boolean }
const DeleteContainer = styled.div<hideThis>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: 15px;
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  ${(args) => args.hideThis && `
    display: none;
  `}
`
  const DeleteIcon = styled.img`
    height: 20px;
  `