import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setShowBuscador, setHiddeBuscador } from '../buscador/buscadorSlice'

import buscarWhite from '../../assets/icons/dots/searchWhite.png'
import buscar from '../../assets/icons/dots/search.png'
import gps from '../../assets/icons/dots/pointerWhite.png'


export default function BuscarDot() {
  const dispatch = useAppDispatch()
  const buscador = useAppSelector((state: TrootState) => state.buscador)
  const card = useAppSelector((state: TrootState) => state.appUi)

  // useEffect(() => {
  //   if (buscador.state !== 'hidden' && card.homeCard.status === 'opening' || card.homeCard.status === 'open') {
  //     dispatch(setHiddeBuscador())
  //   }

  // }, [card.homeCard.status ])

  return(
    <BuscarDotContainer
      onClick={() => {
        if (buscador.state === 'initial' || buscador.state === 'searching')
        dispatch(setHiddeBuscador())
        else
        dispatch(setShowBuscador())
      }}

      isActive={buscador.state === 'initial' || buscador.state === 'searching' ? true : false}
    >

      <BuscarIcon
        src={  buscar}
      />

    </BuscarDotContainer>
  )
}

interface isActive { isActive: boolean}

const BuscarDotContainer = styled.div<isActive>`
  position: fixed;
  bottom: 90px;
  right: 20px;
  background-color: white;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 8px gray;
  ${(args) => !args.isActive && `
    opacity: 0.7;
  `}
`

const BuscarIcon = styled.img`
  height: 30px;
`