import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setLocation } from '../crearNegocio/crearNegocioSlice'
import { setEditLocation, cleanEditLocation } from '../../services/editNegocio/editNegocioSlice'

import GmapMarker from './gmapMarker'
import locationPin from '../../assets/icons/ui/pin-location.png'
import { useLocation } from 'react-router-dom'

export default function GMapWidget() {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  const [ loadingPlace, setLoadingPlace ] = useState(false)

  const position = useAppSelector((state: TrootState) => state.crearNegocio.data)
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)
  const editing = useAppSelector((state: TrootState) => state.editNegocio)

  // Setting map
  useEffect(() => {
    if (ref.current && !map) {
      
      let lat = position.lat ? position.lat : -17.7837026
      let lng = position.lng ? position.lng : -63.1818181 

      if (tarjeta.data) {
        lat = tarjeta.data.lat
        lng = tarjeta.data.lng
      }

      setMap(new window.google.maps.Map(ref.current, {
        center: {
          lat,
          lng
        },
        zoom: 15,
        disableDefaultUI: true,
      }));
    }
  }, [ref, map, position, tarjeta?.data]);

  useEffect(() => {

    if (map  && position.lat && position.lng) {
      const newCenter = new google.maps.LatLng(position.lat, position.lng)
      map.setCenter(newCenter);
    }
    
  }, [position, tarjeta?.data])

  useEffect(() => {
    function endDrag() {
      const lat = map?.getCenter()?.lat()
      const lng = map?.getCenter()?.lng()

      if (editing) {
        dispatch(cleanEditLocation())
      }

      if (lat && lng) {
        dispatch(setLocation({ lat, lng }))
        if (editing.editing) {
          dispatch(setEditLocation({
            lat, lng, googlePlaceId: undefined, text: undefined
          }))
        }
      }
    }

    if (map) {
      new ClickEventHandler(map, {
        lat: position.lat ? position.lat : -17.7837026,
        lng: position.lng ? position.lng : -63.1818181
      })

      map.addListener('dragend', endDrag)

      return () => {
        google.maps.event.clearListeners(map, 'dragend');
      }
    }
  }, [map])


  function isIconMouseEvent(
    e: google.maps.MapMouseEvent | google.maps.IconMouseEvent
  ): e is google.maps.IconMouseEvent {
    return "placeId" in e;
  }

  class ClickEventHandler {
    origin: google.maps.LatLngLiteral;
    map: google.maps.Map;
    directionsService: google.maps.DirectionsService;
    directionsRenderer: google.maps.DirectionsRenderer;
    placesService: google.maps.places.PlacesService;
    infowindow: google.maps.InfoWindow;
    infowindowContent: HTMLElement;
    constructor(map: google.maps.Map, origin: google.maps.LatLngLiteral) {
      this.origin = origin;
      this.map = map;
      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(map);
      this.placesService = new google.maps.places.PlacesService(map);
      this.infowindow = new google.maps.InfoWindow();
      this.infowindowContent = document.getElementById(
        "infowindow-content"
      ) as HTMLElement;
      this.infowindow.setContent(this.infowindowContent);
  
      // Listen for clicks on the map.
      this.map.addListener("click", this.handleClick.bind(this));
    }
  
    handleClick(event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
      console.log("You clicked on: " + event.latLng);
  
      // If the event has a placeId, use it.
      if (isIconMouseEvent(event)) {
        console.log("You clicked on place:" + event.placeId);
  
        // Calling e.stop() on the event prevents the default info window from
        // showing.
        // If you call stop here when there is no placeId you will prevent some
        // other map click event handlers from receiving the event.
        event.stop();
  
        if (event.placeId) {
          // this.calculateAndDisplayRoute(event.placeId);
          this.getPlaceInformation(event.placeId);
        }
      }
    }
  
    // calculateAndDisplayRoute(placeId: string) {
    //   const me = this;

    //   this.directionsService
    //     .route({
    //       origin: this.origin,
    //       destination: { placeId: placeId },
    //       travelMode: google.maps.TravelMode.WALKING,
    //     })
    //     .then((response) => {
    //       me.directionsRenderer.setDirections(response);
    //     })
    //     .catch((e) => window.alert("Directions request failed due to "));
    // }
  
    getPlaceInformation(placeId: string) {
      const me = this;
  
      this.placesService.getDetails(
        { placeId: placeId },
        (
          place: google.maps.places.PlaceResult | null,
          status: google.maps.places.PlacesServiceStatus
        ) => {
          console.log(place)
          if (
            status === "OK" &&
            place &&
            place.geometry &&
            place.name &&
            place.geometry.location
          ) {

            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            const newCenter = new google.maps.LatLng(lat, lng)

            if (map && editing.editing) { 
              map.setCenter(newCenter);
              dispatch(setEditLocation({
                text: place.name,
                googlePlaceId: place.place_id,
                lat,
                lng
              }))
            
            }
          }
        }
      );
    }
  }

  return (
    <>
      <GmapContainer
        ref={ref}
        id="map"
        path={pathname}        
      >

        { map && tarjeta.data?.lat && tarjeta.data?.lng && pathname === '/' &&
          <GmapMarker
            lat={tarjeta.data.lat}
            lng={tarjeta.data.lng}
            map={map}
            idx={0}
            title={tarjeta.data.nombre}
          />
        }

      </GmapContainer>

      { !editing.location.googlePlaceId &&
        <Pin src={locationPin}/>
      }

    </>
  );
}

interface path { path: string }
const GmapContainer = styled.div<path>`
  width: 100%;
  height: 45vh;
  position: relative;
  ${(args) => args.path === '/' && `
    height: 60vh;
  `}
`
const Pin = styled.img`
  width: 70px;
  height: 70px;
  position: absolute;
  left: calc(50% - 35px);
  top: calc(50% - 70px);  
`

