import React from 'react'
import styled from 'styled-components'

export default function Policy() {

  return(
    <Container>
      <PolicyContent>
        <Title> Política de Privacidad para VCards Business </Title>

        <Title> Fecha de Entrada en Vigor: 19/10/2023 </Title>

        <Paraf> Esta Política de Privacidad describe cómo VCards Business ("nosotros", "nuestro", "nos") recopila, utiliza y comparte información cuando utilizas nuestra aplicación móvil VCards Business (en adelante, "la Aplicación").</Paraf>

        <Title> Información que Recopilamos </Title>

        <Title> 1.- Información de Usuario: </Title>

        <Paraf>Cuando creas una cuenta en VCards Business, recopilamos tu nombre, dirección de correo electrónico y contraseña.</Paraf>
        <Paraf>También puedes elegir proporcionar información adicional, como una foto de perfil o detalles de contacto.</Paraf>

        <Title>2.- Información del Negocio:</Title>

        <Paraf>Si decides crear un negocio en nuestra plataforma, recopilamos información como el nombre del negocio, ubicación, descripción, horario de apertura, fotos y otros detalles relevantes.</Paraf>

        <Title>3.- Información de Ubicación:</Title>

        <Paraf>Para proporcionar la funcionalidad de búsqueda de negocios cercanos, recopilamos y procesamos la ubicación de tu dispositivo.</Paraf>
        <Title>4.- Comunicaciones:</Title>

        <Paraf>Podemos recopilar y almacenar registros de comunicaciones realizadas a través de la aplicación, como mensajes y comentarios.</Paraf>
        <Title>Uso de la Información</Title>

        <Paraf>Utilizamos la información recopilada para los siguientes propósitos:</Paraf>

        <Paraf>Facilitar la búsqueda y visualización de negocios cercanos en la plataforma VCards Business.</Paraf>
        <Paraf>Permitir a los usuarios crear y gestionar perfiles de negocios y de usuario.</Paraf>
        <Paraf>Enviar notificaciones relevantes sobre la actividad en la aplicación.</Paraf>
        <Paraf>Mejorar y personalizar la experiencia de usuario en VCards Business.</Paraf>
        <Paraf>Cumplir con las obligaciones legales y regulaciones aplicables.</Paraf>
        <Title>Compartir Información</Title>

        <Paraf>Podemos compartir cierta información con terceros, incluyendo:</Paraf>

        <Paraf>Otros usuarios de VCards Business en el contexto de interacciones y transacciones dentro de la plataforma.</Paraf>
        <Paraf>Proveedores de servicios que nos ayudan a operar y mejorar la aplicación VCards Business.</Paraf>
        <Paraf>Autoridades legales en respuesta a una solicitud válida.</Paraf>

        <Title>Seguridad</Title>

        <Paraf>Implementamos medidas de seguridad para proteger tu información contra accesos no autorizados o alteraciones.</Paraf>

        <Title>Acceso y Control</Title>

        <Paraf>Tienes el derecho de acceder, corregir o eliminar tu información personal. Puedes hacerlo a través de la configuración de tu cuenta en la aplicación VCards Business.</Paraf>

        <Title>Enlaces Externos</Title>

        <Paraf>El sitio web de VCards Business (https://vcardsbusiness.net/) puede contener enlaces a sitios web de terceros. No tenemos control sobre el contenido o las prácticas de privacidad de estos sitios y no asumimos responsabilidad alguna por ellos.</Paraf>

        <Title>Cambios en la Política de Privacidad</Title>

        <Paraf>Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento y te recomendamos que revises periódicamente esta página para obtener información actualizada.</Paraf>

        <Title>Contacto</Title>

        <Paraf>Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, no dudes en ponerte en contacto con nosotros en [dirección de correo electrónico].</Paraf>
      </PolicyContent>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 80px;
  overflow-y: auto;
`

const PolicyContent = styled.div`
  width: 80%;
  margin: auto;
`

  const Title = styled.div`
    font-weight: 500; 
    margin-top: 10px;
  `
  const Paraf = styled.div`
    margin-left: 5px;
  `