import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useGetNegociosQuery } from '../../services/negocios/queryApi'
import { TnegocioItem } from '../../services/negocio/negociosApiTypes'
import { Tnegocios } from '../../services/negocios/apiTypes'
import { useLocation, useRouteLoaderData,  } from 'react-router-dom'

import NegocioRow from '../negocioRow/negocioRow'
import { TrootState, useAppSelector } from '../../app/store'

export default function NegociosList() {
  const location = useLocation()
  const [ negocios, setNegocios ] = useState<Tnegocios>((useRouteLoaderData('root') as Tnegocios))

  const buscador = useAppSelector((state: TrootState) => state.buscador)
  const position = useAppSelector((state: TrootState) => state.position)

  const { data: negociosApi, error, isFetching, isLoading, refetch } = useGetNegociosQuery({
    search: buscador.searchText !== '' ? buscador.searchText : undefined,
    lat: position.lat? position.lat : undefined,
    lng: position.lng? position.lng : undefined,
  } , {
    pollingInterval: 0
  })

  useEffect(() => {
    if (negociosApi) {
      setNegocios(negociosApi)
    }
  }, [negociosApi])

  return(
    <NegociosListContainer
      isMobile={location.pathname}
    >
      <Espaciador
        buscadorActive={ buscador.state === 'hidden' ? false : true }
      />

      {  (negocios as Tnegocios).items.map((negocio: TnegocioItem) => (
        <NegocioRow
          { ...negocio }
          key={negocio.negocio_id}
        />
      ))}
    </NegociosListContainer>
  )
}

interface TnegociosListCont { isMobile: string }
const NegociosListContainer = styled.div<TnegociosListCont>`
  display: flex;
  flex-flow: column nowrap;
 
  z-index: -10;
  width: 100%;
  
  ${(args) => args.isMobile === '/' && `
    padding-bottom: 150px;
  `}
  color: white;
`
interface Tespaciador { buscadorActive: boolean }
const Espaciador = styled.div<Tespaciador>`
  min-height: 75px;
  min-width: 100%;
  transition: all 0.7s;
  ${(args) => args.buscadorActive && `
    min-height: 125px;
  `}
`