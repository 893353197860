import axios from 'axios'
import { app } from '../servicesConfig'
import { AxiosResponse, AxiosError } from 'axios'
import { TuploadNegocioData, TuploadNegocioDataResponse, TuploadImageData, TuploadImageResponse, TsimpleResponse } from './negociosApiTypes'


const BACKEND_URL = app.BACKEND_URL
// let BACKEND_URL = 'https://vinereserveclub.smartssi.net'
// let BACKEND_URL = 'https://preprod-vinereserveclub.smartssi.net'
// let BACKEND_URL = 'https://vinereserveclub.com'

export function uploadNegocioData(payload: TuploadNegocioData) {
  const axiosOptions = {
    method: 'POST',  
    withCredentials: true,
    url: `${BACKEND_URL}/admin/publicar`,
    data: JSON.stringify({
      type: "upload_data",
      ...payload
    }),
  };

  return new Promise<AxiosResponse<TuploadNegocioDataResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}

export function getPresignedUrl(payload: TuploadImageData) {
  const axiosOptions = {
    method: 'POST',  
    withCredentials: true,
    url: `${BACKEND_URL}/admin/publicar`,
    data: JSON.stringify({
      type: "uploadImage",
      ...payload
    }),
  };

  return new Promise<AxiosResponse<TuploadImageResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}

export function getCatalogoPresignedUrl(negocio_id: string) {
  const axiosOptions = {
    method: 'POST',  
    withCredentials: true,
    url: `${BACKEND_URL}/admin/publicar`,
    data: JSON.stringify({
      type: "uploadCatalogo",
      negocio_id
    }),
  };

  return new Promise<AxiosResponse<TuploadImageResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}

export function deleteNegocio(negocioId: string) {
  const axiosOptions = {
    method: 'DELETE',  
    withCredentials: true,
    url: `${BACKEND_URL}/admin/negocio/${negocioId}`,
  };

  return new Promise<AxiosResponse<TsimpleResponse>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject(error)
    })
  })
}

export function uploadFile(file: File|Blob, slug: string) {
  return new Promise<TsimpleResponse>((resolve, reject) => {
    fetch(slug, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      body: file
    })
    .then(data => {
      console.log("Presigned Url: ", 'success');
      //if (data.statusCode && data.statusCode !== 200) { reject(data.message) }
      resolve({ result: 'success' })
    })
    .catch((error) => {
      console.log('userInfo error', error)
      reject(error)
    });
  })
}
