import { createSlice, Action, PayloadAction } from '@reduxjs/toolkit'

type TinitialState = {
  corp_id: string | undefined,
  corp_name: string | undefined,
}

const initialState: TinitialState = {
  corp_id: undefined,
  corp_name: undefined
}

const corporacionesSlice = createSlice({
  name: 'corporaciones',
  initialState,
  reducers: {
    setCorpId: (state, action: PayloadAction<string>) => {
      state.corp_id = action.payload
    },
    setCorpName: (state, action: PayloadAction<string> ) => {
      state.corp_name = action.payload
    }
  }
})

export const {
    setCorpId,
    setCorpName,
  } = corporacionesSlice.actions

export default corporacionesSlice.reducer