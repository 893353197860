import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { saveAs } from 'file-saver';
import ModalNoIcon from '../../../modal/modalNoCloseIcon'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setEditingNegocio } from '../../../../services/editNegocio/editNegocioSlice'
import useConfig from '../../../../hooks/useConfig';

import threeDots from '../../../../assets/icons/tarjeta/options.png'


export default function OptionsButton() {
  const dispatch = useAppDispatch()
  const { app } = useConfig()
  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ canEdit, setCanEdit ] = useState<boolean>(false)

  const user = useAppSelector((state: TrootState) => state.user)
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)
  
  const donwloadQR = ()=>{
    let url = `https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${tarjeta.data?.negocio_id}/vcard_qr.png`
    saveAs(url, "VCardsBussines-QR.png");
  }

  useEffect(() => {
    if (user.data && tarjeta.data) {
      if (user.data.role === 'admin' || user.data.role === 'registrador') {
        setCanEdit(true)
      } else {
        if (tarjeta.data?.user === user.data.username) {
          setCanEdit(true)
        }
      }
    }
  }, [user, tarjeta])

  const hiddeModal = () => setShowModal(false)

  return(
    <>
      <Container
        onClick={() => {
          setShowModal(true)
        }}
      >
        <DotsImage src={threeDots} />
      </Container>

      { showModal && 
        <ModalNoIcon
          closeModal={hiddeModal}
          customId='editNegocio'
        >
          <Menu>
            { canEdit && <Item
                onClick={() => {
                  dispatch(setEditingNegocio(tarjeta.negocioId))
                  setShowModal(false)
                }}
              >
                Editar
              </Item>
            }

            <Item
              onClick={() => {
                donwloadQR()
              }}
            >
              Descargar QR
            </Item>

            <Item
              onClick={() => {
                if (tarjeta.data)
                navigator.share({
                  title: `${tarjeta.data?.nombre} - VCards Bussines`,
                  text: `Dale una mirada a mi tarjeta de Contacto!\n\n${tarjeta.data?.nombre}\n${tarjeta.data?.tipo}\n`,
                  url: `https://vcardsbusiness.net/negocios/${tarjeta.data.negocio_id}`
                })
              }}
            >
              Compartir Link
            </Item>
          </Menu>
        </ModalNoIcon>
      }
    </>
  )

}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba( 0, 0, 0, 0.3);
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0px 10px;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    background-color: rgba( 155, 155, 155, 0.3);
  }
`
  const DotsImage = styled.img`
    width: 25px;
  `

const Menu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
`
  const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    width: 300px;
    height: 48px;
    border-top: 1px solid rgba( 219, 219, 219, 0.8);
    &:first-child {
      border-top: none;
    }
  `