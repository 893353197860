import React, { useEffect, useRef, ReactElement, Children, useState } from 'react'
import styled from 'styled-components'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useLocation } from 'react-router-dom';

import GMapWidget from './gmapWidget'
import loadingDots from '../../../../assets/gifs/loading_dots.gif'

export default function GmapWrapper() {
  const { pathname } = useLocation()

   const render = (status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <LoadingContainer> <LoadingGif src={loadingDots}/> </LoadingContainer>;
      case Status.FAILURE:
        return <ErrorMap> Error on load Google maps API </ErrorMap>;
      case Status.SUCCESS:
        return <GMapWidget/>
    }
  };

  return(
    <GmapContainer
      path={pathname}
      id='gmap-tarjeta'
    >
      <Wrapper apiKey={"AIzaSyDhDx_Dbb34dREDoErolb7N4Ubk2h81nsY"}  render={render}/>
    </GmapContainer>
  )
}

interface path { path: string }
const GmapContainer = styled.div<path>`
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: calc(85vh - 70px);
  ${(args) => args.path === '/' && `
    height: calc(85vh - 60px);
  `}
`
const ErrorMap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 600;
  background-color: rgba(0,0,0,0.8);
  //border: dashed 2px rgb(200, 200, 200);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: 100%;
  color: white;
`


const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(85vh - 70px);
`
  const LoadingGif = styled.img`
    width: 70px;
  `
