import React from 'react'
import styled from 'styled-components'
import { TrootState, useAppSelector } from '../../app/store'
import GmapWrapper from './components/map/googleMapsWrapper'
import useConfig from '../../hooks/useConfig'

export default function TarjetaMap() {
  const { app } = useConfig()
  const tarjeta = useAppSelector((state: TrootState) => state.tarjeta)

  return(
    <TarjetaMapContainer>

      <GmapWrapper/>

      <NegocioThumb>
        <NegocioImg
          src={`https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${tarjeta.data?.negocio_id}/thumb_250x250.jpg`}
        />

        <Nombre>
          { tarjeta.data?.nombre }
        </Nombre>

      </NegocioThumb>

    </TarjetaMapContainer>
  )
}

const TarjetaMapContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
`
const Nombre = styled.div`
  font-family: Roboto;
  width: 260px;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  margin: 10px;
  color: white;
`

const NegocioThumb = styled.div`
  position: absolute;
  top: 30px;
  left: 0px;
  width: calc(90vw - 20px);
  background-color: #00000088;
  display: flex;
  margin-left: 10px;
`

const NegocioImg = styled.img`
  width: 70px;
  
`
const FooterMap = styled.div`
  
`