import React from 'react'
import styled from 'styled-components'

import BuscarDot from '../../components/buscarDot/buscarDot'
import Buscador from '../../components/buscador/buscador'
import NegociosList from '../../components/list_items/negociosList'


export default function Home() {
  
  return(
    <HomeContainer
      className='view'
    >

      <NegociosList/>
      <Buscador/>
      <BuscarDot/>

    </HomeContainer>
  )
}

const HomeContainer = styled.div`

`