import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../../../app/store'
import { setAlreadyEdited, setUpdating, cleanSlice } from '../../../../services/editNegocio/editNegocioSlice'
import { updateNegocioInfo } from '../../../../services/editNegocio/api'

import loading from '../../../../assets/gifs/infinity-loader.gif'



export default function EditPhotos() {
  const dispatch = useAppDispatch()
  const [ edited, setEdited ] = useState<boolean>(false)

  const editing = useAppSelector((state: TrootState) => state.editNegocio)

  useEffect(() => {
    const edited = editing.dataSteps[0].map((item) => item.edited)
  
    if (edited.includes(true)) setEdited(true)
    else setEdited(false)
  }, [editing])
  
  function saveDataChanges() {
    const filtered = editing.dataSteps[0].filter((item) => item.edited === true)
    const payload = filtered.reduce((acc: any, item: any) => {
      let { prop, value } = item
  
      return { ...acc, [prop]: value }
    }, {})
  
    dispatch(setUpdating('updating'))
    updateNegocioInfo({ negocio_id: editing.editing, ...payload })
    .then((resp) => {
      dispatch(setAlreadyEdited(0))
      dispatch(setUpdating('updated'))      
    })
    .catch((err) => {
      console.log(err)
      dispatch(setUpdating('initial'))
    })
  }

  return(
    <Container>

      <Botonera>
        { editing.updating === 'updating' ?
            <LoadingContainer>
              <LoadingSpinner src={loading}/>
            </LoadingContainer>
          :
            <Save
              edited={edited}
              disabled={!edited}
              onClick={() => {
                saveDataChanges()
              }}
            >
              Guardar
            </Save>
        }

        <Cancel
          onClick={() => {
            dispatch(cleanSlice())
          }}
        >
          Cerrar
        </Cancel>

      </Botonera>

    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex-grow: 2;
`


const Botonera = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid rgb(209, 209, 209);
`
  const Button = styled.button`
    width: 100px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    font-family: Nunito, sans-serif;
    font-weight: 600;
  `

  interface Tsave { edited: boolean }
  const Save = styled(Button)<Tsave>`
    color: rgb(219,219,219);
    ${(args) => args.edited && `
      color: #ff2a7f;
    `}
  `
  const Cancel = styled(Button)``

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  `
  const LoadingSpinner = styled.img`
    height: 25px;
  `