import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setShowBuscador, setHiddeBuscador, setSearchText } from './buscadorSlice'

import close from '../../assets/icons/ui/closeGrey.png'

export default function Buscador() {
  const dispatch = useAppDispatch()

  const buscador = useAppSelector((state: TrootState) => state.buscador)

  function ChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event.target.value)
    dispatch(setSearchText(event.target.value))
  }

  useEffect(() => {
    if (buscador.state === 'initial') {
      const input: any = document.getElementById("redbol-buscador-negocios");
      input.focus();
    }
  }, [buscador.state])

  return(
    <BuscadorCotainer
      isActive={ buscador.state === 'hidden' ? false : true }
    >

      <BuscadorInput
        id="redbol-buscador-negocios"
        type='text'
        autoFocus
        placeholder='Buscar'
        value={buscador.searchText}
        onChange={ChangeInput}
        disabled={ buscador.state === 'hidden' ? true : false}
      />

      <Close
        onClick={() => {
          dispatch(setHiddeBuscador())
        }}
      >
        <CloseIcon src={close} />
      </Close>
    </BuscadorCotainer>
  )
}

interface BarActive { isActive: boolean }
const BuscadorCotainer = styled.div<BarActive>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: white;
  background-color: #F2F2F2;
  padding: 20px 0px 10px 0px;
  transition: all 0.7s;
  ${(args) => args.isActive && `
    top: 60px;
  `}
`

const BuscadorInput = styled.input`
  width: calc(100% - 70px);
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 20px;
  font-size: 14px;
  font-family: Roboto;
  padding: 4px 8px;  
`

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`
  const CloseIcon = styled.img`
    width: 15px;
    height: 15px;
  `