import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TrootState, useAppDispatch, useAppSelector } from '../../app/store'
import { setStep, setInitialState } from './crearNegocioSlice'
import { filterOnlyAllowedProps } from '../../libs/utils'
import { setLogEvent } from '../popUp/popUpSlice'
import { uploadNegocioData, getPresignedUrl, getCatalogoPresignedUrl, uploadFile } from '../../services/negocio/api'
import { TuploadNegocioData } from '../../services/negocio/negociosApiTypes'
// import { setAdminView } from '../../views/admin/adminSlice'
import { setHiddeCrearNegocio } from '../../services/admin/adminSlice'

import { logEvent } from '../../services/logger/api'
import Loader from '../loader/loader'

import ModalNoIcon from '../modal/modalNoCloseIcon'

import NegocioData from './crearNegocioSteps/negocioDataStep'
import ContactoData from './crearNegocioSteps/contactoDataStep'
import RedesData from './crearNegocioSteps/redesDataStep'
import LocationData from './crearNegocioSteps/locationDataStep'
import ImageData from './crearNegocioSteps/imageStep'
import RefererCode from './crearNegocioSteps/refererCode.step'

import loading from '../../assets/gifs/loading.gif'
import backIcon from '../../assets/icons/crear/back-arrow.png'
import nextIcon from '../../assets/icons/crear/next-arrow.png'


const requiredProps = [
  'nombre',
  'tipo',
  //'distrito',
  //'localidad',
  'phone_negocio',

  'nombre_contacto',
  'phone_contacto',

  // 'lat',
  // 'lng',
]

export default function CrearNegocio() {
  const dispatch = useAppDispatch()
  const publicar = useAppSelector((state: TrootState) => state.crearNegocio)
  const [fotos, setFotos] = useState<Blob[]>([])
  const [catalogo, setCatalogo] = useState<Blob|undefined>(undefined)
  const [ uploading, setUploading ] = useState<boolean>(false)

  
  function nexStep() {
    if (publicar.step < 5)
    dispatch(setStep(publicar.step + 1))
  }

  function backStep() {
    if (publicar.step > 0)
    dispatch(setStep(publicar.step - 1))
  }


  async function guardarNegocio() {
    setUploading(true)

    const props = filterOnlyAllowedProps( publicar.data ,requiredProps)
    const areUndfined = Object.entries(props).some(([prop, value]) => {
      console.log(`prop ${prop} -- value ${value}`)
      return value === undefined
    } )

    if (areUndfined) {
      setUploading(false)
      dispatch(setLogEvent({
        type:'error',
        message: 'No se han llenado todos los campos requeridos, vuelve a los pasos anteiores'
      }))

    } else {

      uploadNegocioData(({...publicar.data} as TuploadNegocioData ))
      .then(async (resp) => {
        if (resp.data.collection_id) {
          const negocioId = resp.data.collection_id

          if (catalogo) {
            const catalogoPromise = () => {
              return new Promise((resolve, reject) => {
                const catalogoFile = new File([catalogo], `catalogo`);
                getCatalogoPresignedUrl(negocioId)
                  .then((resp) => {
                    if (resp.data.urlS3Image) {
                      uploadFile(catalogoFile, resp.data.urlS3Image)
                      .then(() => {
                        resolve(true)
                        logEvent({ message: 'UPLOAD IMAGE S3', path: negocioId })
                      })
                      .catch((err) => {
                        console.log(err)
                        setUploading(false)
                        dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
                        logEvent({ message: err.response.data.message, path: negocioId })
                        resolve(false)    
                      })
                    }
                    
                  })
                  .catch((err) => {
                    console.log(err)
                    setUploading(false)
                    dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
                    logEvent({ message: err.response.data.message, path: negocioId })
                    resolve(false)
                  })
              })
            }

            await catalogoPromise()
          }
        

          const imagesData = fotos.map((blob, idx) => {
            const file = new File([blob], `foto_${idx}`);
            return ({ file,  name: `foto_${idx}` })
          })

          const uploadImagesPromises = imagesData.map((image) => {
            return new Promise((resolve, reject) => {
              getPresignedUrl({ negocio_id: negocioId , image_name: image.name })
              .then((resp) => {
                if (resp.data.urlS3Image) {
                  uploadFile(image.file, resp.data.urlS3Image)
                  .then(() => {
                    resolve(true)
                    logEvent({ message: 'UPLOAD IMAGE S3', path: negocioId })
                  })
                  .catch((err) => {
                    console.log(err)
                    setUploading(false)
                    dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
                    logEvent({ message: err.response.data.message, path: negocioId })
                    resolve(false)
                  })
                }
                
              })
              .catch((err) => {
                console.log(err)
                setUploading(false)
                dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
                logEvent({ message: err.response.data.message, path: negocioId })
                resolve(false)
              })
            })
            
          })

          Promise.all(uploadImagesPromises)
          .then(() => {
            
            console.log('All Uploaded')
            dispatch(setLogEvent({ type: 'info', message: 'Negocio publicado correctamente' }))
            logEvent({ message: 'Negocio publicado correctamente', path: negocioId })
            setTimeout(() => {
              dispatch(setInitialState())
              // dispatch(setAdminView('list'))
              dispatch(setHiddeCrearNegocio())
              setUploading(false)
            }, 2000)
            
          })
        }
      })
      .catch((err) => {
        console.log('error...')
        console.log(err)
        setUploading(false)
        dispatch(setLogEvent({ type: 'error', message: err.response.data.message }))
      })

    }
  }

  function closeModal() {
    dispatch(setHiddeCrearNegocio())
  }

  return(
    <>
    <ModalNoIcon closeModal={closeModal}>
      <CrearNegocioContainer className='CrearNegocioContainer'>
        <div style={{width: '100%'}}>

          <Navigator>
            { Array.apply(null, Array(6)).map((dot, idx) => (
              <Dot 
                key={`dot-nav-${idx}`}
                isActive={idx === publicar.step}
              />
            ))}
          </Navigator>

          <Header>
            
            <BackArrow
              disabled={publicar.step === 0 ? true : false}
              onClick={() => {
                if (publicar.step > 0) backStep() 
              }}
            >
              <ArrowImg src={backIcon}/>
            </BackArrow>
            

            <Title> Crear Negocio </Title>
            
            <NextArrow
              disabled={publicar.step === 5 ? true : false}
              onClick={() => {
                if (publicar.step < 5) nexStep() 
              }}
            >
              <ArrowImg src={nextIcon}/>
            </NextArrow>

          </Header>


          <Container>
            { publicar.step === 0 && <RefererCode/> }
            { publicar.step === 1 && <NegocioData/> }
            { publicar.step === 2 && <ContactoData/> }
            { publicar.step === 3 && <RedesData/> }
            { publicar.step === 4 && <LocationData/> }
            { publicar.step === 5 && <ImageData
                loadFotos={setFotos} fotos={fotos}
                loadCatologo={setCatalogo} catalogo={catalogo}
              /> 
            }
          </Container>
        </div>

        <div>
          <Botonera>
            
              <Atras
                disabled={publicar.step === 0 ? true : false}
                onClick={() => {
                  if (publicar.step > 0) backStep() 
                }}
              >
                Volver
              </Atras>
            
            
            { publicar.step < 5 &&
              <Siguiente onClick={() => nexStep() } >
                Continuar
              </Siguiente>
            }

            { publicar.step === 5 &&
              <Guardar
                disabled={uploading}
                onClick={() => guardarNegocio() }
              >
                { uploading ? '' : 'Guardar' }
                <LoadingGif isLoading={uploading} src={loading}/>
              </Guardar>
            }
          </Botonera>
        </div>

      </CrearNegocioContainer>
    </ModalNoIcon>

    { uploading &&
      <Loader/>
    }
    </>
  )
}

const CrearNegocioContainer = styled.div`
  width: 90%;
  height: calc(100vh - 160px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px 0px;
  border-radius: 10px;
  margin: auto;
`

const Title = styled.div`
  font-family: Roboto;
  width: 100%;
  font-size: 18px;
  text-align: center;
  
  margin-bottom: 0px;
  font-weight: 500;
`

const Container = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 330px);
  margin-top: 20px;
`

const Botonera = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`

const Button = styled.button`
  padding: 5px 0px;
  width: 100px;
  border: none;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
`

const Atras = styled(Button)<navButtons>`
  background-color: #BFB0A0;
  ${(args) => args.disabled && `
    opacity: 0.5;
  `}
`
const Siguiente = styled(Button)`
  color: white;
  background-color: #E73C37;
`

const Guardar = styled(Button)`
  color: white;
  background-color: #50B2c0;
`

const Navigator = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`
interface isActive { isActive: boolean }
const Dot = styled.div<isActive>`
  background-color: #201e1f55;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${(args) => args.isActive && `
    background-color: #201e1f;
  `}
`

interface TloadingGif { isLoading: boolean }
const LoadingGif = styled.img<TloadingGif>`
  width: 23px;
  display: none;
  ${(args) => args.isLoading && `
    display: initial;
  `}
`

const Header = styled.div`
  display: flex;
  max-width: 300px;
  margin: auto;
  align-items: center;
  margin-top: 5px;
`
  interface navButtons { disabled: boolean }
  const BackArrow = styled.div<navButtons>`
    ${(args) => args.disabled && `
      opacity: 0.2;
    `}
  `
  const NextArrow = styled.div<navButtons>`
    ${(args) => args.disabled && `
      opacity: 0.2;
    `}
  `

  const ArrowImg = styled.img`
    width: 35px;
  `

