import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useConfig from '../../hooks/useConfig'

import { TcorpDataItem } from '../../services/negocio/negociosApiTypes'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
import { useAppDispatch } from '../../app/store'
import { setNegocioId } from '../../services/tarjeta/tarjetaSlice'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import loading from '../../assets/icons/ui/eclipse.svg'
import colapseImg from '../../assets/icons/corp/collapse.png'
import colapseImgActive from '../../assets/icons/corp/collapse_active.png'
import personal from '../../assets/icons/corp/personal.png'

export default function CorporacionDataRow(negocio: TcorpDataItem) {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [, setSearchParam] = useSearchParams()
  const [colapse, setColapse] = useState(false)
  const { app } = useConfig()

  interface imageEffect {
    alt: string,
    src: string,
    className?: string,
    height?: string | number,
    width?: string | number,
  }

  function distanceFormat(distance: number) {
    if (distance < 1 ) 
    return Math.round(distance * 1000) + ' mt'
    else
    return Math.round(distance * 10) / 10 + ' km'
  }

  const MyImage = useMemo(() => (image: imageEffect) => (
    <LazyLoadImage
      className={image.className ? image.className : ''}
      alt={image.alt}
      wrapperClassName='wrapperImageFit'
      effect="black-and-white"
      src={image.src}
      height={ image.height ? image.height : 'auto' }
      width={ image.width ? image.width : 'auto' }
      placeholderSrc={loading}
    />
  ), [])

  function openTarjeta() {
    if (negocio.tarjeta) {
      setSearchParam({ negocio: negocio.tarjeta })
      dispatch(setNegocioId(negocio.tarjeta))
    }
  }

  return(
    // <Link
    //   to={`/negocios/${negocio.negocio_id}`}
    //   state={{ background: location }}
    //   style={{ textDecoration: 'none' }}
    // >
    <>
      <NegocioRowContainer>

        <Left
          onClick={() => openTarjeta()}
        >
          <ThumbContainter>
            <Thumb>
            <MyImage
              className='imageFit'
              alt="member image"
              src={ negocio.tarjeta ?
                `https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/negocios/${negocio.tarjeta}/thumb_150x150.jpg`
                : `https://s3.sa-east-1.amazonaws.com/${app.STATIC_BUCKET}/corporaciones/${negocio.corp_id}/thumb_150x150.jpg`
              }
              width={'100%'}
              height={'100%'}
            />
            </Thumb>
          </ThumbContainter>
        </Left>

        <Center
          onClick={() => openTarjeta()}
        >
          <Row1>
            <NegocioTitle> { negocio.nombre } </NegocioTitle>
            
          </Row1>

          <Row2>
            <NegocitoTipo> { negocio.cargo ? negocio.cargo : negocio.categoria } </NegocitoTipo>
          </Row2>
        </Center>

        <Right>
          {/* { negocio.distance && <Distance> { distanceFormat(negocio.distance) } </Distance> }
          <div></div> */}
          { negocio.categoria === 'sucursal' && 
            <Colapse
              onClick={() => setColapse(!colapse)}
            >
              <ColapseImg src={ colapse ? colapseImgActive: colapseImg}/>
            </Colapse>
          }
        </Right>

      </NegocioRowContainer>

      
        <PersonalList
          colapse={colapse}
        >

          <PersonalItem>
            <PeronsalThumb>
              <PersonalImg src={personal} />
            </PeronsalThumb>

            <PesonalText>
              Personal 1
            </PesonalText>
          </PersonalItem>
          <PersonalItem>
            <PeronsalThumb>
              <PersonalImg src={personal} />
            </PeronsalThumb>

            <PesonalText>
              Personal 2
            </PesonalText>
          </PersonalItem>
          <PersonalItem>
            <PeronsalThumb>
              <PersonalImg src={personal} />
            </PeronsalThumb>

            <PesonalText>
              Personal 3
            </PesonalText>
          </PersonalItem>

        </PersonalList>
      
    </>
    // </Link>
  )
}

const NegocioRowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 20px;
  min-width: 90%;
  
`
const Left = styled.div``
  const Thumb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #ffffffcc;
  `
    const ThumbContainter = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 78px;
      border-radius: 5px;
      //background-color: black;
      background: linear-gradient(180deg, rgba(180,180,180,0) 0%, rgba(180,180,180,1) 100%);
      //background: linear-gradient(135deg, rgba(180,180,180,0) 70%, rgba(100,100,100,1) 100%);
    `
  

const Center = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 4;
  padding: 0px 10px;
`

const Right = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex-grow: 8;
  padding: 0px 0px 0px 0px;
  min-width: 60px;
  position: relative;
`

const Row = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
  const Row1 = styled(Row)`
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 40px;
    width: 100%;
  `
  const Row2 = styled(Row)`
    justify-content: flex-start;
    height: 40px;
  `
  const NegocioTitle = styled.p`
    font-family: Roboto;
    font-weight: 500;
    color: white;
    color: black;
    font-size: 13px;
    margin: 0px;
  `
  const NegocitoTipo = styled.p`
    font-family: Roboto;
    color: gray;
    font-size: 13px;
    margin: 0px;
    max-height: 20px;
    overflow: hidden;
  `
  const Distance = styled.div`
    font-family: Roboto, sans-serif;
    color: #50b2c0;
    color: #ffffff;
    color: black;
    
    background: linear-gradient(180deg, #868f96   0%, #596164 100%);
    background: #434343;
    background: transparent;
    border: solid 1px #434343;
    border-radius: 2px;
    width: 50px;
    align-self: flex-end;
    position: absolute;
    top: 5px;

    
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 15px; // <= whatch out
  `

  const Colapse = styled.div`
    align-self: flex-end;
    
  `
    const ColapseImg = styled.img`
      width: 15px;
      margin: 5px;  
    `

interface Tcolapse { colapse: boolean }
const PersonalList = styled.div<Tcolapse>`
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
  margin-bottom: 20px;
  height: 0px;
  overflow: hidden;
  transition: all 0.8s ease;
  ${(args) => args.colapse && `
    height: 250px;
  `}
`
  const PersonalItem = styled.div`
    display: flex;
    margin: 0px 20px;
    padding: 0px 5px;
    background-color: #ebedee;
    border-radius: 5px;
  `

    const PeronsalThumb = styled.div``
    const PersonalImg = styled.img`
      height: 60px;
    `
    const PesonalText = styled.div`
      font-family: nunito, sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin-top: 14px;
      color: black;
    `