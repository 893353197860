import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import fetch, { Headers, Request, Response } from 'cross-fetch';
import { app } from '../servicesConfig'
//import AbortController from 'abort-controller';

global.fetch = fetch as any;
global.Headers = Headers as any;
global.Request = Request as any;
global.Response = Response as any;
//global.AbortController = AbortController;

// const BACKEND_URL = 'https://api.redbolivianacomercial.ocuba.net';
const BACKEND_URL = app.BACKEND_URL

export const negociosApi =  createApi({
  reducerPath: 'negociosApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}`,
    fetchFn: fetch,
  }),
  endpoints: (builder) => ({

    getNegocios: builder.query({
      query: (args) => {
        let url = '/negocios?'
        if (args.search) url += `&search=${args.search}`
        if (args.lat) url += `&lat=${args.lat}`
        if (args.lng) url += `&lng=${args.lng}`
        if (args.tipos?.length > 0) {
          const queryTiposArray = args.tipos.map((tipo: string) => {
            return `&tipo=${tipo}`
          })
          url += queryTiposArray.join('')
        } 

        return {
          url,
        }
      }
    }),
    getLastRegistered: builder.query({
      query: (args) => {
        
        return {
          url: '/negocios/lastRegistered',
          credentials: 'include'
        }
      }
    }),
    getMisPublicaciones: builder.query({
      query: (args) => {
        
        return {
          url: '/admin/mis-publicaciones',
          credentials: 'include'
        }
      }
    }),
    getFavoritos: builder.query({
      query: (args) => {
        
        return {
          url: '/negocios/favoritos',
          credentials: 'include'
        }
      }
    }),
    getCorporaciones: builder.query({
      query: (args) => {
        
        return {
          url: '/corporaciones',
          credentials: 'include'
        }
      }
    }),
    getCorporacion: builder.query({
      query: (args) => {
        return {
          url: `/corporaciones/${args.corp_id}`,
          credentials: 'include'
        }
      }
    }),

  })
})

export const {
  useGetNegociosQuery,
  useGetMisPublicacionesQuery,
  useGetLastRegisteredQuery,
  useGetFavoritosQuery,
  useGetCorporacionesQuery,
  useGetCorporacionQuery
} = negociosApi